import React, { useCallback } from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Button, Flex } from '@chakra-ui/react';

export default function CreateCsThreadButton() {
  const { t } = webCore.contexts.useTranslation();
  const { dispatch } = webApp.contexts.useModal();

  const openSubjectModal = useCallback(() => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_CS_SUBJECTS,
      props: {},
    });
  }, []);
  return (
    <Flex flex={1} px={5} pb={2}>
      <Button w="full" variant="filled" onClick={openSubjectModal}>
        {t('Create a new thread')}
      </Button>
    </Flex>
  );
}
