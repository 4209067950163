import React, { useMemo, useRef, useCallback } from 'react';
import firebase from '@happylife-a/firebase';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Flex, Box, useDisclosure, Text, useToast } from '@chakra-ui/react';
import {
  CopyTextIcon,
  EditMessageIcon,
  ForwardIcon,
  ReplyIcon,
  DeleteIcon,
} from '../../theme/icons';
import Avatar from '../Avatar';
import ChannelMessageCard from '../ChannelMessageCard';
import { MessageCard, CustomerSupportThreadCard } from '../MessageCard';
import ActionsMenu from './ActionsMenu';
import ImageMessage from './ImageMessage';
import Reactions from './Reactions';

const SECTION_WIDTH_AVATAR = '50px';
const SECTION_WIDTH_ACTION_NON_SENDER = '96px';
const SECTION_WIDTH_ACTION_SENDER = '80px';

export default function Message({
  message,
  chatRoom,
  messageId,
  onOver,
  onMouseLeave,
  onReply,
  onOpenForwardModal,
  setEditMessage,
  editMessage,
  onOpenDeleteModal,
  messageCardHeight,
  onAddReaction,
  firebaseRoomId,
  firebaseThreadId,
  setShowMessageParams,
  messagingType = firebase.libraries.messaging.MESSAGING_TYPES.CHATTING,
  setIsOpenMessageTab,
  setIsOpenChatTab,
}) {
  const { user } = webCore.contexts.useUser();
  const { t } = webCore.contexts.useTranslation();

  const isSender = utils.helpers.id.same(message?.sender?.id, user.id);
  const scrollTargetRef = useRef(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const toast = useToast();

  const checkRoomType = webCore.hooks.chat.useCheckRoomType();
  const roomType = checkRoomType(chatRoom);

  const hookLib = webCore.coreHooks.messaging[messagingType];

  const updateMessage = hookLib.useUpdateMessage({
    firebaseRoomId: firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    messageId: message?.id,
  });

  const [avatarKey, nickname] = useMemo(() => {
    const valueAvatarKey = message?.sender?.avatarKey;
    const valueNickname = message?.sender?.name || 'UNKNOWN';

    return [valueAvatarKey, valueNickname];
  }, [message?.sender?.id]);

  const messageElements =
    firebase.libraries.messaging.chatting.helpers.parseAndHighlightUrls(
      message.type ===
        firebase.libraries.messaging.chatting.constants
          .MESSAGE_TYPE_FORWARDED && message.forward
        ? message.forward.content
        : message.message.content
    );

  const isOnlyImageMessage = messageElements?.length === 0;

  const actions = [
    {
      name: t('Reply'),
      icon: <ReplyIcon fill="none" w={6} h={6} />,
      action: () => onReply(message),
    },
    {
      name: t('Copy'),
      icon: <CopyTextIcon fill="none" w={6} h={6} />,
      action: () => {
        const copyText =
          message.type ===
            firebase.libraries.messaging.chatting.constants
              .MESSAGE_TYPE_FORWARDED && message.forward
            ? message.forward.content
            : message.message.content;

        navigator.clipboard.writeText(copyText);
        toast({ status: 'success', title: t('Copied') });
      },
    },
    {
      name: t('Edit'),
      icon: <EditMessageIcon fill="none" w={6} h={6} />,
      action: () => setEditMessage(message),
    },
    {
      name: t('Forward'),
      icon: <ForwardIcon fill="none" w={6} h={6} />,
      action: () => onOpenForwardModal(message),
    },
    {
      name: t('Delete'),
      icon: <DeleteIcon fill="none" w={6} h={6} />,
      action: onOpenDeleteModal,
    },
  ];

  const filteredActions = actions.filter((action) => {
    if (action.name === t('Edit')) {
      return isSender && !isOnlyImageMessage;
    } else if (action.name === t('Delete')) {
      return isSender;
    } else if (action.name === t('Copy')) {
      return !isOnlyImageMessage;
    }
    return true;
  });

  const onMouseOut = () => {
    onMouseLeave();
    onClose();
  };
  const images = message.medias || [];
  const reactions = message.reactions || {};

  const toggleReaction = useCallback(
    (reactionName) => {
      if (Object.keys(reactions).length === 1) {
        onMouseLeave();
      }
      const messageInfo = firebase.libraries.messaging[
        messagingType
      ].message.toggleReaction(message, reactionName, user.id);

      updateMessage(messageInfo);
    },
    [message, reactions]
  );

  return (
    <Flex
      px={2}
      py={1}
      borderRadius="4px"
      flexDir="column"
      ref={scrollTargetRef}
      onMouseOver={(e) => onOver(e, message)}
      onMouseLeave={onMouseOut}
      cursor="pointer"
      bgColor={
        (messageId === message.id ||
          (editMessage && editMessage.id === message.id)) &&
        !roomType.isChannel &&
        !roomType.isCustomerSupport &&
        message.type !==
          firebase.libraries.messaging.chatting.constants
            .MESSAGE_TYPE_FAVORITE_COLLECTION
          ? 'secondary.50'
          : 'transparent'
      }
      data-item="1"
    >
      <Flex
        gap={2}
        alignSelf={!isSender ? 'flex-start' : 'flex-end'}
        alignItems="center"
        justifyContent="space-between"
        w="full"
        data-item="2"
      >
        {isSender && !roomType.isChannel && !roomType.isCustomerSupport && (
          <Box width={SECTION_WIDTH_ACTION_NON_SENDER}>
            {message.id === messageId &&
              !roomType.isChannel &&
              !roomType.isCustomerSupport && (
                <Flex gap={4}>
                  <Box>
                    <ActionsMenu
                      actions={filteredActions}
                      isSender={isSender}
                    />
                  </Box>
                  <Flex alignItems="center">
                    <Reactions
                      isOpen={isOpen}
                      onClose={onClose}
                      onToggle={onToggle}
                      isSender={isSender}
                      messageCardHeight={messageCardHeight}
                      onAddReaction={onAddReaction}
                    />
                  </Flex>
                </Flex>
              )}
          </Box>
        )}

        {!isSender && (
          <Box
            flexShrink={0}
            width={{
              base: '',
              md: roomType?.isChannel ? 0 : SECTION_WIDTH_AVATAR,
            }}
          >
            {!roomType.isChannel && (
              <Avatar
                image={s3Storage.helpers.read.buildUrlProfileAvatar(avatarKey)}
                firstName={nickname}
                lastName={nickname}
                width={10}
                height={10}
                borderRadius={20}
                color="white"
                background="green.50"
              />
            )}
          </Box>
        )}

        <Box
          width={{
            base: '100%',
            md:
              roomType.isChannel || roomType.isCustomerSupport
                ? '100%'
                : `calc(
                  100%
                  - ${isSender ? SECTION_WIDTH_ACTION_SENDER : SECTION_WIDTH_ACTION_NON_SENDER}
                  ${!isSender ? `+ ${SECTION_WIDTH_AVATAR}` : ''}
                )`,
          }}
        >
          <Flex flex={1} flexDir="column" gap={1}>
            {roomType.isChannel || roomType.isCustomerSupport ? (
              <>
                {roomType.isChannel ? (
                  <ChannelMessageCard
                    message={message}
                    isSender={isSender}
                    setShowMessageParams={setShowMessageParams}
                    firebaseRoomId={firebaseRoomId}
                  />
                ) : (
                  <CustomerSupportThreadCard
                    message={message}
                    isSender={isSender}
                    setShowMessageParams={setShowMessageParams}
                    firebaseRoomId={firebaseRoomId}
                  />
                )}
              </>
            ) : (
              <MessageCard
                message={message}
                nickname={nickname}
                users={chatRoom?.users}
                isSender={isSender}
                setShowMessageParams={setShowMessageParams}
                firebaseRoomId={firebaseRoomId}
                chatRoom={chatRoom}
                setIsOpenMessageTab={setIsOpenMessageTab}
                setIsOpenChatTab={setIsOpenChatTab}
                messageElements={messageElements}
              />
            )}

            {images &&
              images.length > 0 &&
              message?.type !==
                firebase.libraries.messaging.chatting.constants
                  .MESSAGE_TYPE_THREAD && <ImageMessage images={images} />}

            <Flex
              flexDir="row"
              alignSelf={!isSender ? 'flex-start' : 'flex-end'}
            >
              {reactions && (
                <Flex gap={2}>
                  {Object.keys(reactions).map((name) => {
                    // @TODO: maybe we need this later
                    // const reactionByMe = reactions[name].includes(user.id);
                    const reactionsCount = reactions[name].length;

                    return (
                      <Flex
                        key={`reaction/${name}`}
                        data-reactions-name={name}
                        data-reactions-emoji={
                          firebase.libraries.messaging.chatting.reactions
                            .reactionsByName[name]
                        }
                        data-reactions-count={reactionsCount}
                        onClick={() => toggleReaction(name)}
                        w={6}
                        h={6}
                        justifyContent="center"
                        alignItems="center"
                        bgColor="grey.50"
                        borderRadius="full"
                      >
                        <Text variant="subhead-6">
                          {
                            firebase.libraries.messaging.chatting.reactions
                              .reactionsByName[name]
                          }
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Box>

        {!isSender && (
          <Box
            width={{
              base: '',
              md: roomType?.isChannel ? 0 : SECTION_WIDTH_ACTION_SENDER,
            }}
          >
            {message.id === messageId &&
              chatRoom?.type !==
                firebase.libraries.messaging.chatting.constants
                  .CHAT_ROOM_TYPE_CHANNEL && (
                <Flex gap={2}>
                  <Flex alignItems="center">
                    <Reactions
                      isOpen={isOpen}
                      onClose={onClose}
                      onToggle={onToggle}
                      isSender={isSender}
                      messageCardHeight={messageCardHeight}
                      onAddReaction={onAddReaction}
                    />
                  </Flex>
                  <Box>
                    <ActionsMenu
                      actions={filteredActions}
                      isSender={isSender}
                    />
                  </Box>
                </Flex>
              )}
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
