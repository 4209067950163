export function truncate(str, length = 25) {
  if (str) {
    const moreCharacter = '…';
    return str.length > length
      ? str.substring(0, length - moreCharacter.length) + moreCharacter
      : str;
  }

  return str;
}

export function trim(value) {
  return value
    .replace(/^\\t/g, '')
    .replace(/\\t$/g, '')
    .replace(/^\s+/g, '')
    .replace(/\s+$/g, '');
}

export function zerofill(value, length) {
  return ('0'.repeat(length) + value).slice(-1 * length);
}
