import * as localization from '@happylife-a/localization';
import utils from '@happylife-a/utils';
import {
  branding,
  mobileApp,
  theming,
  contactsAndLinks,
  contentPrivacyPolicy,
  contentTermsConditions,
  contentDeliveryPolicy,
  contentReturnPolicy,
} from './defines';

function merge(obj1, obj2) {
  Object.entries(obj2).forEach(([key, value]) => {
    obj1[key] = value;
  });
}

export function mergeJsonData({
  branding: appBranding,
  mobileApp: appMobileApp,
  theming: appTheming,
  contactsAndLinks: appContactsAndLinks,
  contentPrivacyPolicy: appContentPrivacyPolicy,
  contentTermsConditions: appContentTermsConditions,
  contentDeliveryPolicy: appContentDeliveryPolicy,
  contentReturnPolicy: appContentReturnPolicy,
}) {
  merge(branding, appBranding);
  merge(mobileApp, appMobileApp);
  merge(theming, appTheming);
  merge(contactsAndLinks, appContactsAndLinks);
  merge(contentPrivacyPolicy, appContentPrivacyPolicy);
  merge(contentTermsConditions, appContentTermsConditions);
  merge(contentDeliveryPolicy, appContentDeliveryPolicy);
  merge(contentReturnPolicy, appContentReturnPolicy);
}

export function configure({ branding: appBranding }) {
  localization.setLocalesAndDefault(
    appBranding.countryParams.locales.list,
    appBranding.countryParams.locales.default
  );

  const currency = appBranding.countryParams.currency;
  utils.helpers.currency.setDefaultCurrency(currency);
}
