import { useMutation, useQuery } from '@tanstack/react-query';
import {
  REACT_QUERY_GET_CUSTOMER_GRANT,
  REACT_QUERY_GET_CUSTOMER_GRANTS,
} from '../constants';
import customerGrantUseCase from '../factories/customerGrant';

export const useGetCustomerGrantStatus = (options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANT],
    customerGrantUseCase.getCustomerGrantStatus,
    options
  );

  return query;
};

export const useGetCustomerGrants = (variables, options = {}) => {
  const query = useQuery(
    [REACT_QUERY_GET_CUSTOMER_GRANTS, variables],
    () => customerGrantUseCase.getCustomerGrants(variables),
    options
  );

  return query;
};

export const useUpdateCustomerGrantStatus = () => {
  const mutation = useMutation(customerGrantUseCase.updateCustomerGrantStatus);
  return mutation;
};

export const useRequestCustomerGrant = () => {
  const mutation = useMutation(customerGrantUseCase.requestCustomerGrant);
  return mutation;
};
