import React, { useMemo } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react';
import ScrollableBox from '../ScrollableBox';

/**
 * options has 3 types:
 * 1) options={[{ value: 1, label:'First', value: 2, label: 'Second' }]}
 * 2) options={{ 1: 'First', 2: 'Second' }}
 * 3) options={[ obj1, obj2, obj3 ]} -- extractor method required.
 */
function Dropdown({
  options: providedOptions,
  extractor = null,
  placeholder,
  value,
  onChange,
  isInvalid = false,
  ...rest
}) {
  const options = useMemo(() => {
    if (Array.isArray(providedOptions)) {
      if (providedOptions.length === 0) {
        return [];
      }

      const fields = Object.keys(providedOptions[0]);
      if (
        fields.length === 2 &&
        fields.includes('value') &&
        fields.includes('label')
      ) {
        return providedOptions;
      }

      if (typeof extractor !== 'function') {
        throw new Error('Extractor method required for rendering dropdown.');
      }

      return providedOptions.map((item, index) => extractor(item, index));
    }

    return Object.keys(providedOptions).reduce((acc, option) => {
      acc.push({ value: option, label: providedOptions[option] });
      return acc;
    }, []);
  }, [providedOptions, extractor]);

  const selectedIndex = useMemo(() => {
    return options.findIndex((option) => option.value === value);
  }, [value, options]);

  return (
    <Menu>
      <MenuButton
        as={Box}
        p={2}
        width="100%"
        textAlign="left"
        borderRadius="md"
        borderWidth="1px"
        {...rest}
        {...(!isInvalid
          ? {}
          : {
              borderColor: 'red',
            })}
      >
        {selectedIndex !== -1 ? options[selectedIndex]?.label : placeholder}
      </MenuButton>
      <ScrollableBox
        primary={false}
        as={MenuList}
        maxH="300px"
        overflow={'auto'}
      >
        {options.map((item, index) => (
          <MenuItem
            key={`CustomSelect-${index}-${item.value}`}
            onClick={() => onChange(item.value)}
            value={item.value}
            _hover={{ bg: 'grey.300' }}
            _focus={{ bg: 'grey.300' }}
          >
            {item.label}
          </MenuItem>
        ))}
      </ScrollableBox>
    </Menu>
  );
}

export default Dropdown;
