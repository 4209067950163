import React, {
  useContext,
  createContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import utils from '@happylife-a/utils';
import {
  useGetCartItemsIds,
  useShoppingCarts as useCoreShoppingCarts,
} from '../core/hooks/shoppingCartHooks';
import { reduceCartsTotal } from '../helpers';
import { useUser } from './UserContext';

export const ShoppingCartContext = createContext({
  isCostCalculating: false,
  carts: {},
  total: 0,
  toggleCart: () => {},
  isInCart: () => {},
  onCartOpen: () => {},
  updateCartCount: () => {},
  bulkDeleteCarts: () => {},
  refetchCarts: () => {},
  setCartItemsTotal: () => {},
});

export function useShoppingCarts() {
  const context = useContext(ShoppingCartContext);
  if (!context) {
    throw new Error('Please use hook inside of ShoppingCartProvider.');
  }

  return context;
}

export function ShoppingCartProvider({ children }) {
  const { isLoading: isLoadingUser, isUnknownUser, user } = useUser();

  const { data: carts, refetch: loadShoppingCartIds } = useGetCartItemsIds({
    enabled: false,
  });

  const [cartItemsTotal, setCartItemsTotal] = useState();
  const [totalCost, totalDiscountedCost, totalProductItemsCount] =
    cartItemsTotal || [0, 0, 0];

  useEffect(() => {
    if (carts?.ids) {
      setCartItemsTotal(reduceCartsTotal(carts.ids));
    }
  }, [carts]);

  const isInCart = useCallback(
    (productDetailId) => {
      return (
        carts?.ids?.findIndex((id) =>
          utils.helpers.id.same(id.productDetailId, productDetailId)
        ) > -1
      );
    },
    [carts]
  );

  const [isCostCalculating, setIsCostCalculating] = useState(false);
  const { toggleCart, updateCartCount, bulkDeleteCarts } = useCoreShoppingCarts(
    isInCart,
    {
      setIsCostCalculating: setIsCostCalculating,
    }
  );

  useEffect(() => {
    if (isUnknownUser || isLoadingUser || !user?.id) {
      return;
    }

    loadShoppingCartIds();
  }, [isUnknownUser, isLoadingUser, user?.id]);

  return (
    <ShoppingCartContext.Provider
      value={{
        carts: carts,
        isInCart: isInCart,
        isCostCalculating: isCostCalculating,
        totalCost: totalCost,
        totalDiscountedCost: totalDiscountedCost,
        totalProductItemsCount: totalProductItemsCount,
        toggleCart: toggleCart,
        updateCartCount: updateCartCount,
        bulkDeleteCarts: bulkDeleteCarts,
        refetchCarts: loadShoppingCartIds,
        setCartItemsTotal: setCartItemsTotal,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
