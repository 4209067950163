import * as defines from './defines';
import * as main from './main';

export * from './defines';
export * from './main';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...main,
  ...defines,
};
