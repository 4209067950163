import { useCallback, useRef, useState } from 'react';

export default function useForceRender() {
  const fakeRender = useRef(0);
  const [, setRender] = useState(0);

  const increment = useCallback(() => {
    setRender(++fakeRender.current);
  }, []);

  const forceRender = useCallback((delay = -1) => {
    if (typeof delay === 'number' && delay >= 0) {
      setTimeout(() => increment(), delay);
    } else {
      increment();
    }
  }, []);

  return forceRender;
}
