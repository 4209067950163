import React from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import { Flex, Link, Text } from '@chakra-ui/react';
import ChannelMessageCard from './ChannelMessageCard';
import ForwardedMessageCard from './ForwardedMessageCard';
import ProductMessageCard from './ProductMessageCard';
import RepliedMessageCard from './RepliedMessageCard';
import ShareCollectionMessage from './ShareCollectionMessage';
import ThreadMessage from './ThreadMessage';

const chatConstants = firebase.libraries.messaging.chatting.constants;

function MessageCard({
  message,
  nickname,
  isSender,
  setShowMessageParams,
  firebaseRoomId,
  chatRoom,
  setIsOpenMessageTab,
  setIsOpenChatTab,
  messageElements,
}) {
  const { t } = webCore.contexts.useTranslation();
  const timeInstance = new Date(message.timestamp);
  const formattedTime = [
    timeInstance.getHours().toString().padStart(2, '0'),
    timeInstance.getMinutes().toString().padStart(2, '0'),
  ].join(':');

  return (
    <Flex flex={1} flexDir="column">
      {!isSender && (
        <Flex gap={3} alignItems="center">
          <Text variant="sub-head-med">{`${nickname}`}</Text>
          <Text variant="body-reg-md" color="grey.300">
            {formattedTime}
          </Text>
        </Flex>
      )}
      <Flex flexDir="column" gap="2px">
        {message.type === chatConstants.MESSAGE_TYPE_REPLY && message.reply && (
          <RepliedMessageCard
            message={message}
            isSender={!isSender}
            chatRoom={chatRoom}
          />
        )}

        {message.type === chatConstants.MESSAGE_TYPE_FORWARDED &&
          message.forward && (
            <ForwardedMessageCard
              isSender={!isSender}
              message={message}
              formattedTime={formattedTime}
            />
          )}

        {message.type === chatConstants.MESSAGE_TYPE_FAVORITE_COLLECTION && (
          <ShareCollectionMessage
            message={message}
            setIsOpenMessageTab={setIsOpenMessageTab}
            setIsOpenChatTab={setIsOpenChatTab}
          />
        )}
        {message.type === chatConstants.MESSAGE_TYPE_CHANNEL && (
          <ChannelMessageCard message={message} />
        )}
        {message.type === chatConstants.MESSAGE_TYPE_PRODUCT && (
          <ProductMessageCard message={message} />
        )}

        {message.type === chatConstants.MESSAGE_TYPE_THREAD && (
          <ThreadMessage
            message={message}
            isSender={!isSender}
            formattedTime={formattedTime}
            setShowMessageParams={setShowMessageParams}
            firebaseRoomId={firebaseRoomId}
          />
        )}

        {isSender &&
          message.type !== chatConstants.MESSAGE_TYPE_REPLY &&
          message.type !== chatConstants.MESSAGE_TYPE_FORWARDED &&
          message.type !== chatConstants.MESSAGE_TYPE_THREAD && (
            <Text variant="body-reg-md" color="grey.300" textAlign="right">
              {formattedTime}
            </Text>
          )}
        <Text variant="sub-head-reg" textAlign={!isSender ? 'left' : 'right'}>
          {messageElements?.length > 0 &&
            messageElements?.map((element, index) =>
              element.type === 'link' ? (
                <Link
                  key={index}
                  as="a"
                  href={element.value}
                  target="_blank"
                  color="blue.300"
                >
                  {element.value}
                </Link>
              ) : (
                <Text key={index} as="span" variant="sub-head-red">
                  {element.value}
                </Text>
              )
            )}
        </Text>
        {message?.message.edited && (
          <Text
            variant="body-reg-md"
            color="grey.600"
            textAlign={!isSender ? 'left' : 'right'}
          >
            {t('Edited')}
          </Text>
        )}
        {isSender && message.type === chatConstants.MESSAGE_TYPE_REPLY && (
          <Text variant="body-reg-md" color="grey.300" textAlign="right">
            {formattedTime}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}

export default MessageCard;
