import React from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Text } from '@chakra-ui/react';

export default function Header({
  roomType,
  chatRoomParams,
  onCloseMessageTab,
}) {
  const { t } = webCore.contexts.useTranslation();

  return (
    <>
      <Box
        height={roomType?.isChannel || roomType?.isGroup ? 74 : 12}
        w="full"
      />
      <Flex
        w="full"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
        position="fixed"
        top={0}
        bgColor="white.50"
        zIndex={999}
      >
        <Flex justifyContent="center" alignItems="center" p="12px 0px 0px 16px">
          <Box as="span" flex="1" textAlign="left">
            <Text variant="sub-head-semi">
              {utils.helpers.string.truncate(
                roomType?.isCustomerSupport
                  ? t('Customer support')
                  : chatRoomParams?.chatRoom?.roomName,
                20
              )}
            </Text>
            {chatRoomParams?.chatRoom?.type !==
              firebase.libraries.messaging.chatting.constants
                .CHAT_ROOM_TYPE_DIRECT && (
              <Text variant="sub-head-reg">
                {chatRoomParams?.chatRoom?.membersCount} {t('participants')}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex
          height="100%"
          alignItems="center"
          position="relative"
          pt={3}
          pr={4}
          zIndex={1}
          onClick={onCloseMessageTab}
          justifyContent="flex-end"
        >
          <webApp.theme.icons.ChatCloseIcon fill="grey.900" w={5} h={5} />
        </Flex>
      </Flex>
    </>
  );
}
