import * as components from './components';
import * as compositions from './compositions';
import * as constants from './constants';

const DefineFormFieldValueModal =
  compositions.CreateProduct.DefineFormFieldValueModal;

const ChooseProductImageModal =
  compositions.CreateProduct.ChooseProductImageModal;

const ChooseProductVariationalTitleModal =
  compositions.CreateProduct.ChooseProductVariationalTitleModal;

// const GooglePlaceAutocompleteMapModal =
//   components.GooglePlaceAutocompleteMapModal;

const DropPointModal = components.DropPointModal;

const OrderDenyModal = components.OrderDenyModal;

const ListTrackOrdersModal = components.ListTrackOrdersModal;
const DeleteMessageModal = components.DeleteMessageModal;
const ForwardModal = components.ForwardModal;

const YandexPlaceAutocompleteMapModal =
  components.YandexPlaceAutocompleteMapModal;

const modals = {
  [constants.ENUM_MODAL_SELLER_CHOOSE_PRODUCT_IMAGE]: ChooseProductImageModal,
  [constants.ENUM_MODAL_SELLER_DEFINE_FORM_FIELD_VALUE]:
    DefineFormFieldValueModal,
  [constants.ENUM_MODAL_SELLER_CHOOSE_PRODUCT_VARIATIONAL_TITLE]:
    ChooseProductVariationalTitleModal,
  [constants.ENUM_MODAL_SELLER_CHOOSE_PRODUCT_VARIATIONAL_TITLE]:
    ChooseProductVariationalTitleModal,
  [constants.ENUM_MODAL_YANDEX_PLACE_AUTOCOMPLETE_MAP]:
    YandexPlaceAutocompleteMapModal,

  [constants.ENUM_MODAL_SELLER_CHOOSE_DROP_OF_POINT_ADDRESS]: DropPointModal,

  [constants.ENUM_MODAL_LIST_TRACK_ORDERS]: ListTrackOrdersModal,
  [constants.ENUM_MODAL_DELETE_MESSAGE]: DeleteMessageModal,
  [constants.ENUM_MODAL_FORWARD_MESSAGE]: ForwardModal,

  [constants.ENUM_MODAL_PENDING_ORDER_DENY]: OrderDenyModal,
};

export default modals;
