import http, { API_MESSAGING } from '../http';

export default function MessagingChatRoomService(messagingType) {
  const getChatRooms = async (params) => {
    const response = await http(API_MESSAGING).get(
      `/messaging/${messagingType}/chat-rooms`,
      {
        params: params,
      }
    );
    return response.data;
  };

  const getChatRoomById = async (chatRoomId) => {
    const response = await http(API_MESSAGING).get(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}`
    );
    return response.data;
  };

  const getChatRoomsFromAll = async (params) => {
    const response = await http(API_MESSAGING).get(
      '/messaging/all-chat-rooms',
      {
        params: params,
      }
    );
    return response.data;
  };

  const getChatRoomByIdFromAll = async (chatRoomId) => {
    const response = await http(API_MESSAGING).get(
      `/messaging/all-chat-rooms/${chatRoomId}`
    );
    return response.data;
  };

  const searchChatRooms = async (search) => {
    const response = await http(API_MESSAGING).get(
      `/messaging/${messagingType}/chat-rooms/search`,
      {
        params: { search: search },
      }
    );
    return response.data;
  };

  const createChatRoom = async (params) => {
    const response = await http(API_MESSAGING).post(
      `/messaging/${messagingType}/chat-rooms`,
      params
    );
    return response.data;
  };

  const createOrGetChatRoom = async (params) => {
    const response = await http(API_MESSAGING).post(
      `/messaging/${messagingType}/chat-rooms/bulk`,
      params
    );
    return response.data;
  };

  const connectShopToChannel = async ({ chatRoomId, sellerId }) => {
    const response = await http(API_MESSAGING).post(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}/connect-shop`,
      { sellerId: sellerId }
    );
    return response.data;
  };

  const deleteChatRoom = async ({ firebaseRoomId }) => {
    const response = await http(API_MESSAGING).delete(
      `/messaging/${messagingType}/chat-rooms/${firebaseRoomId}`
    );
    return response.data;
  };

  const updateLastMessage = async ({ firebaseRoomId, message }) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${firebaseRoomId}/last-message`,
      {
        message: message,
      }
    );

    return response.data;
  };

  const renameChatRoom = async (chatRoomId, roomName) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}/room-name`,
      {
        roomName: roomName,
      }
    );
    return response.data;
  };

  const changeChatRoomAvatar = async (chatRoomId, avatarKey) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}/avatar-key`,
      {
        avatarKey: avatarKey,
      }
    );

    return response.data;
  };

  const archiveChatRoom = async (chatRoomId) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}/archive`
    );
    return response.data;
  };

  const unarchiveChatRoom = async (chatRoomId) => {
    const response = await http(API_MESSAGING).patch(
      `/messaging/${messagingType}/chat-rooms/${chatRoomId}/unarchive`
    );
    return response.data;
  };

  const addUserToChatRoom = async ({ firebaseRoomId, memberId }) => {
    const response = await http(API_MESSAGING).post(
      `/messaging/${messagingType}/chat-rooms/${firebaseRoomId}/users/${memberId}`
    );
    return response.data;
  };

  const removeUserFromChatRoom = async ({ firebaseRoomId, memberId }) => {
    const response = await http(API_MESSAGING).delete(
      `/messaging/${messagingType}/chat-rooms/${firebaseRoomId}/users/${memberId}`
    );
    return response.data;
  };

  const getCustomerSupportRoomsFromAdmin = async () => {
    const response = await http(API_MESSAGING).get(
      '/messaging/customer-support/chat-rooms'
    );
    return response.data;
  };

  return {
    getChatRooms: getChatRooms,
    getChatRoomById: getChatRoomById,
    getChatRoomsFromAll: getChatRoomsFromAll,
    getChatRoomByIdFromAll: getChatRoomByIdFromAll,
    searchChatRooms: searchChatRooms,
    createChatRoom: createChatRoom,
    createOrGetChatRoom: createOrGetChatRoom,
    deleteChatRoom: deleteChatRoom,
    updateLastMessage: updateLastMessage,
    renameChatRoom: renameChatRoom,
    changeChatRoomAvatar: changeChatRoomAvatar,
    archiveChatRoom: archiveChatRoom,
    unarchiveChatRoom: unarchiveChatRoom,
    addUserToChatRoom: addUserToChatRoom,
    removeUserFromChatRoom: removeUserFromChatRoom,
    getCustomerSupportRoomsFromAdmin: getCustomerSupportRoomsFromAdmin,
    connectShopToChannel: connectShopToChannel,
  };
}
