import React, { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { theme } from '../../../theme';

export default function Variant5({ value, onChange, onKeyUp, setValue }) {
  const onClose = useCallback((e) => {
    setValue('');
  }, []);
  const { t } = webCore.contexts.useTranslation();
  return (
    <Tooltip label={t('AppSearchHelpText')} closeOnClick={false} bg="black.50">
      <InputGroup w="100%" maxW="176">
        <InputLeftElement h="29px" cursor="pointer">
          <Icon as={theme.icons.SearchIconBlack} fill="none" w={4} h={4} />
        </InputLeftElement>

        <Input
          h="29px"
          type="text"
          placeholder={t('Search')}
          borderRadius="4px"
          bg="white"
          boxShadow="none"
          _placeholder={{
            color: 'grey.600',
          }}
          borderWidth={1}
          borderColor="grey.600"
          borderStyle="solid"
          _focus={{ boxShadow: 'none', borderColor: 'grey.300' }}
          _hover={{ borderColor: 'grey.600' }}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        <InputRightElement h="29px" cursor="pointer" onClick={onClose}>
          <Icon as={theme.icons.SearchCloseIconV2} fill="none" w={4} h={4} />
        </InputRightElement>
      </InputGroup>
    </Tooltip>
  );
}
