import React, { useCallback, useEffect, useState } from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Spinner } from '@chakra-ui/react';
import ChannelThreadMessageList from '~components/Chat/Channels/ChannelThreadMessageList';
import useChatRoom from '~components/Chat/MessagesTab/useChatRoom';
import ThreadPageHeader from '~components/Chat/Mobile/ThreadPage/Header';
import ThreadDetails from '~components/Chat/ThreadMessageTab/ThreadDetails';
import useDisableBodyScroll from '~hooks/useDisableBodyScroll';

const localStorageKeyForState = 'ChatThread@state';

export default function ChatThreadPage() {
  const [shouldUpdateScrollTop, setShouldUpdateScrollTop] = useState(true);
  const [reply, setReply] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [products, setProducts] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const { dispatch } = webApp.contexts.useModal();

  const screenSize = webApp.contexts.useScreenSize();

  useDisableBodyScroll({
    screenWidthCondition: false,
    isDisabled: true,
  });

  const params = webApp.router.useParams();
  const location = webApp.router.useLocation();
  const navigate = webApp.router.useNavigate();

  const [stateFromStorage, setStateFromStorage] = useState(null);
  useEffect(() => {
    webCore.helpers.storage
      .getItem(localStorageKeyForState)
      .then((result) => setStateFromStorage(result || null));
  }, []);

  const state = location?.state || stateFromStorage || {};

  const firebaseThreadId = params?.id;
  const newState = { ...state, firebaseThreadId: firebaseThreadId };

  useEffect(() => {
    webCore.helpers.storage.setItem(localStorageKeyForState, newState);
    setStateFromStorage(newState);
  }, [firebaseThreadId, newState]);

  const { data: response, isLoading } =
    webCore.coreHooks.messaging.common.useGetChatRoomById({
      chatRoomId: state?.firebaseRoomId,
    });

  const hookLib = webCore.coreHooks.messaging[newState?.messagingType];

  const isEndedThread = hookLib.useOnChatThreadEnd({
    firebaseRoomId: newState?.firebaseRoomId,
    firebaseThreadId: newState?.firebaseThreadId,
    threadMessageId: newState?.threadMessageId,
    initialState: newState?.message?.message.isEnded || false,
  });

  const onOpenProductsModal = () => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_PRODUCTS,
      props: { setProducts: setProducts },
    });
  };

  const chatRoomParams = useChatRoom({
    initialChatRoom: response?.chatRoom,
  });

  const onReply = useCallback(
    (swipedMessage) => {
      if (!isEndedThread) {
        setReply(swipedMessage);
      }
    },
    [isEndedThread]
  );

  const closeReply = useCallback(() => {
    setReply(null);
  }, []);

  const onBack = useCallback(() => {
    webCore.helpers.storage.removeItem(localStorageKeyForState);
    navigate(`/chat/${newState?.firebaseRoomId}`);
  }, [navigate]);

  const checkRoomType = webCore.hooks.chat.useCheckRoomType();
  const roomType = checkRoomType(chatRoomParams?.chatRoom);

  return (
    <Flex w="100%" flex={1}>
      {isLoading ? (
        <Flex w="full" flex={1} align="center" justify="center" pt={3}>
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" flex={1}>
          <ThreadPageHeader
            roomType={roomType}
            chatRoomParams={chatRoomParams}
            newState={newState}
            onCloseThreadTab={onBack}
          />
          {chatRoomParams && (
            <>
              {screenSize.isMinTablet && (
                <ThreadDetails details={newState?.message} />
              )}
              <ChannelThreadMessageList
                key={chatRoomParams?.firebaseRoomId}
                chatRoom={chatRoomParams}
                shouldUpdateScrollTop={shouldUpdateScrollTop}
                setShouldUpdateScrollTop={setShouldUpdateScrollTop}
                onReply={onReply}
                setEditMessage={setEditMessage}
                editMessage={editMessage}
                firebaseThreadId={newState?.firebaseThreadId}
                showMessageParams={newState}
                roomType={roomType}
                products={products}
                isImageSelected={isImageSelected}
                newState={newState}
                reply={reply}
              />
              {!isEndedThread && (
                <webApp.components.ChatInput
                  messagingType={newState?.messagingType}
                  firebaseRoomId={chatRoomParams?.firebaseRoomId}
                  firebaseThreadId={newState?.firebaseThreadId}
                  threadMessageId={newState?.threadMessageId}
                  setShouldUpdateScrollTop={setShouldUpdateScrollTop}
                  reply={reply}
                  userName={chatRoomParams?.chatRoom?.roomName}
                  closeReply={closeReply}
                  editMessage={editMessage}
                  setEditMessage={setEditMessage}
                  chatRoom={chatRoomParams?.chatRoom}
                  threadTab={true}
                  onOpenProductsModal={onOpenProductsModal}
                  products={products}
                  setProducts={setProducts}
                  setIsImageSelected={setIsImageSelected}
                />
              )}
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}
