import React from 'react';
import webCore from '@happylife-a/web-core';
import { Flex, Spinner } from '@chakra-ui/react';
import MessageProductCard from './MessageProductCard';

export default function ProductMessageCard({ message }) {
  const productDetailIds = message?.message?.products?.map(
    (product) => product.productDetailId
  );
  const idsQueryParam = productDetailIds?.join(',');

  const { data, isInitialLoading } =
    webCore.coreHooks.product.useGetProductsDetails({
      ids: idsQueryParam,
    });

  return (
    <Flex
      flex={1}
      flexDir="column"
      gap={3}
      borderWidth={1}
      borderRadius={12}
      borderColor="secondary.50"
      p={2}
    >
      {isInitialLoading ? (
        <Flex flex={1} h={200} justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          {data?.productsDetails?.map((item) => (
            <MessageProductCard
              key={`product-${item.id}`}
              product={item?.product}
              productDetail={item}
            />
          ))}
        </>
      )}
    </Flex>
  );
}
