import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import utils from '@happylife-a/utils';
import apiPingUseCase from '../../core/factories/apiPing';

const STATE_LOADING = 'loading';
const STATE_UNHEALTHY = 'unhealthy';
const STATE_HEALTHY = 'healthy';

export const ApisContext = createContext({
  isHealthy: true,
  apis: {},
});

export function useApis() {
  const context = useContext(ApisContext);
  if (!context) {
    throw new Error('Please use hook inside of ApisProvider.');
  }

  return context;
}

export function ApisProvider({ children }) {
  const [healthityState, setHealthityState] = useState(STATE_LOADING);
  const [apiInfo, setApiInfo] = useState({});

  const checkApis = useCallback(async function (apiName, promise) {
    let response = null;
    let isHealthy = false;

    try {
      response = await promise;
      isHealthy = response.message === 'pong';

      if (isHealthy) {
        utils.helpers.logging.debug(`Ping to ${apiName} succeed:`, {
          version: response.version,
        });
      } else {
        utils.helpers.logging.debug(`Ping to ${apiName} failed.`);
      }
    } catch (e) {
      // ...
    }

    if (isHealthy) {
      delete response.message;
      setApiInfo((old) => ({ ...old, [apiName]: response }));
    }

    return isHealthy;
  }, []);

  const checkAllApisHealth = useCallback(async () => {
    const isHealthyCoreApi = await Promise.all([
      apiPingUseCase
        .getApis()
        .map((apiName) =>
          checkApis(apiName, apiPingUseCase.pingToApi(apiName))
        ),
    ]).then((results) => results.every((isHealthy) => isHealthy));

    setHealthityState(isHealthyCoreApi ? STATE_HEALTHY : STATE_UNHEALTHY);
  }, []);

  useEffect(() => {
    checkAllApisHealth();
  }, []);

  if (healthityState === STATE_LOADING) {
    return null;
  }

  return (
    <ApisContext.Provider
      value={{
        isHealthy: healthityState === STATE_HEALTHY,
        apis: apiInfo,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
}
