import React, { useCallback, useEffect } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { theme } from '../../../../theme';
import ColorPicker from './ColorPicker';

function buildKey() {
  return 'key_' + utils.helpers.random.randomId(10);
}

function buildEmptyValues(availableLocales) {
  return availableLocales.reduce((acc, availableLocale) => {
    acc[availableLocale] = '';
    return acc;
  }, {});
}

function buildEmptyRow(availableLocales) {
  return {
    values: buildEmptyValues(availableLocales),
    hint: '',
  };
}

const commonStyle = {
  height: '48px',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'grey.300',
};

export const fillDefaultLocale = 'en';
export default function Form({ form, valueType }) {
  const { t, available } = webCore.contexts.useTranslation();

  const onAddClick = useCallback(() => {
    const values = form.getValues()?.values;
    values[buildKey()] = buildEmptyRow(available);
    form.setValue('values', values);
  }, []);

  const onRemoveClick = useCallback((valueKey) => {
    const values = form.getValues()?.values;
    delete values[valueKey];
    form.setValue('values', values);
  }, []);

  useEffect(() => {
    form.setValue('values', { [buildKey()]: buildEmptyRow(available) });
  }, []);

  const values = form.watch('values') || {};

  const onValueChange = useCallback((event, valueKey) => {
    for (const availableLocale of available) {
      const setNewValueKey = `values.${valueKey}.values.${availableLocale}`;
      form.setValue(setNewValueKey, event.target.value);
    }
  }, []);

  return (
    <Box>
      <VStack direction="column" spacing={5} width="100%">
        {Object.keys(values).map((valueKey, index) => {
          const errorStateValue =
            form.formState?.errors?.values?.[valueKey]?.values?.[
              fillDefaultLocale
            ];

          const errorStateHint =
            form.formState?.errors?.values?.[valueKey]?.hint;

          return (
            <HStack key={`Form/${index}/${valueKey}`} spacing={5} width="100%">
              {valueType === 'color' ? (
                <>
                  <Box width="50%">
                    <ColorPicker
                      pickerStyle={{ ...commonStyle, width: '100%' }}
                      form={form}
                      isInvalid={!!errorStateHint}
                      {...form.register(`values.${valueKey}.hint`, {
                        required: t('This field is required'),
                      })}
                    />
                    {errorStateHint && (
                      <Text variant="body-reg-sm" color="red.500">
                        {errorStateHint.message}
                      </Text>
                    )}
                  </Box>
                  <Box width="50%">
                    <Input
                      {...commonStyle}
                      isInvalid={!!errorStateValue}
                      width="100%"
                      {...form.register(
                        `values.${valueKey}.values.${fillDefaultLocale}`,
                        {
                          required: t('This field is required'),
                          onChange: (e) => onValueChange(e, valueKey),
                        }
                      )}
                    />
                    {errorStateValue && (
                      <Text variant="body-reg-sm" color="red.500">
                        {errorStateValue.message}
                      </Text>
                    )}
                  </Box>
                </>
              ) : (
                <Box width="100%">
                  <Input
                    {...commonStyle}
                    width="100%"
                    {...form.register(
                      `values.${valueKey}.values.${fillDefaultLocale}`,
                      {
                        required: true,
                        onChange: (e) => onValueChange(e, valueKey),
                      }
                    )}
                  />
                </Box>
              )}

              <Box width="32px">
                <IconButton
                  background="transparent"
                  _hover={{
                    background: 'transparent',
                  }}
                  icon={
                    <theme.icons.DeleteSellerFormFieldValue
                      fill="none"
                      w="32px"
                      h="32px"
                    />
                  }
                  onClick={() => onRemoveClick(valueKey)}
                />
              </Box>
            </HStack>
          );
        })}
      </VStack>

      <Button variant="outlineBlack" marginTop={5} onClick={onAddClick}>
        {t('AppAddAnother')}
      </Button>
    </Box>
  );
}
