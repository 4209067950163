import * as query from './enums/query';
import * as auth from './enums/auth';
import * as formField from './enums/formField';
import * as draftProduct from './enums/draftProduct';
import * as product from './enums/product';
import * as order from './enums/order';
import * as payment from './enums/payment';
import * as delivery from './enums/delivery';
import * as user from './enums/user';
import * as analytics from './enums/analytics';
import * as category from './enums/category';
import * as collaborator from './enums/collaborator';
import * as banner from './enums/banner';
import * as mobileApp from './enums/mobileApp';
import * as templatedNotification from './enums/templatedNotification';
import * as grant from './enums/grant';
import * as legalInfo from './enums/legalInfo';

export {
  query,
  auth,
  formField,
  draftProduct,
  product,
  order,
  payment,
  delivery,
  user,
  analytics,
  category,
  collaborator,
  banner,
  mobileApp,
  grant,
  legalInfo,
  templatedNotification,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query: query,
  auth: auth,
  formField: formField,
  draftProduct: draftProduct,
  product: product,
  order: order,
  payment: payment,
  delivery: delivery,
  user: user,
  analytics: analytics,
  category: category,
  collaborator: collaborator,
  banner: banner,
  grant: grant,
  legalInfo: legalInfo,
  mobileApp: mobileApp,
  templatedNotification: templatedNotification,
};
