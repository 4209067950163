/** @type {import('./types/branding').BrandingInterface} */
export const branding = {};

/** @type {import('./types/mobileApp').MobileAppInterface} */
export const mobileApp = {};

/** @type {import('./types/theming').ThemingInterface} */
export const theming = {};

/** @type {import('./types/contactsAndLinks').ContactsAndLinksInterface} */
export const contactsAndLinks = {};

/** @type {import('./types/contents').PrivacyPolicyInterface} */
export const contentPrivacyPolicy = {};

/** @type {import('./types/contents').TermsConditionsInterface} */
export const contentTermsConditions = {};

/** @type {import('./types/contents').DeliveryPolicyInterface} */
export const contentDeliveryPolicy = {};

/** @type {import('./types/contents').ReturnPolicyInterface} */
export const contentReturnPolicy = {};
