import React, {
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  // locales
  DEFAULT_LOCALE,
  LOCALE_HY,
  LOCALE_EN,
  LOCALE_RU,
  locales as availableLocales,
  localeNames,
  momentLocales,
  // data
  translate as nativeTranslate,
  mergeTranslationsMap,
  translations,
  loadTranslations,
} from '@happylife-a/localization';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/hy-am';
import * as storage from '../helpers/storage';

const CURRENT_LOCALE_STORAGE_KEY = 'TranslationContext@currentLocale';
async function setCurrentLocaleToStorage(locale) {
  return storage.setItem(CURRENT_LOCALE_STORAGE_KEY, locale);
}

export function getCurrentLocaleFromStorage() {
  return storage.getItem(CURRENT_LOCALE_STORAGE_KEY);
}

export { DEFAULT_LOCALE, LOCALE_HY, LOCALE_EN, LOCALE_RU };

export const TranslationContext = createContext({
  t: () => '',
  available: availableLocales,
  localeNames: {},
  onLocaleChange: () => {},
});

export function useTranslation() {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('Please use hook inside of TranslationProvider.');
  }

  return context;
}

export function TranslationProvider({
  children,
  translations: providedTranslations,
  locale: initialLocale = DEFAULT_LOCALE,
  onLocaleChange = () => {},
}) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    loadTranslations().then(() => setIsLoading(false));
  }, []);

  const [locale, setLocale] = useState(initialLocale);
  const mergedTranslations = useMemo(() => {
    return availableLocales.reduce((acc, loopLocale) => {
      acc[loopLocale] = {
        ...providedTranslations[loopLocale],
        ...translations[loopLocale],
      };
      return acc;
    }, {});
  }, [isLoading, providedTranslations]);

  useEffect(() => {
    mergeTranslationsMap(mergedTranslations);
    moment.locale(momentLocales[locale]);
  }, [mergedTranslations]);

  useEffect(() => {
    setCurrentLocaleToStorage(locale);
  }, [locale]);

  const t = useCallback(
    (text, data = {}) => nativeTranslate(locale, text, data),
    [locale, mergedTranslations]
  );

  const onChange = useCallback(
    (newLocale) => {
      setLocale(newLocale);
      moment.locale(momentLocales[newLocale]);
      if (typeof onLocaleChange === 'function') {
        onLocaleChange(newLocale);
      }
    },
    [onLocaleChange]
  );

  return (
    <TranslationContext.Provider
      value={{
        available: availableLocales,
        locale: locale,
        defaultLocale: DEFAULT_LOCALE,
        localeNames: localeNames,
        onLocaleChange: onChange,
        t: t,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
}
