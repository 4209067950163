import MessagingFactory from './MessagingFactory';

export const customerSupport = new MessagingFactory({
  database: null,
  prefix: 'customerSupport',
});
export const chatting = new MessagingFactory({
  database: null,
  prefix: 'chatting',
});

export function onConfigure(firebaseInstances) {
  const messagingInstances = [customerSupport, chatting];

  for (const messagingInstance of messagingInstances) {
    messagingInstance.setDatabase(firebaseInstances.database);
    messagingInstance.configure();
  }
}
