import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import firebase from '@happylife-a/firebase';
import * as messagingHooks from '../../core/hooks/messagingHooks';

function buildStateKey({ messagingType, firebaseRoomId, firebaseThreadId }) {
  return [
    `MessagingType:${messagingType || 'unknown'}`,
    `FirebaseRoomId:${firebaseRoomId || 'unknown'}`,
    `FirebaseThreadId:${firebaseThreadId || 'unknown'}`,
  ].join('/');
}

const ITEMS_PER_PAGE = 20;

export function useLoadMessages() {
  const [state, setState] = useState({});
  const [configs, setConfigs] = useState({
    firebaseRoomId: null,
    firebaseThreadId: null,
    itemsPerPage: ITEMS_PER_PAGE,
    messagingType: null,
  });

  const refLastConfigureParams = useRef();
  const configure = useCallback((params) => {
    refLastConfigureParams.current = params;
    setConfigs({
      firebaseRoomId: params.firebaseRoomId || null,
      firebaseThreadId: params.firebaseThreadId || null,
      itemsPerPage: params.itemsPerPage || ITEMS_PER_PAGE,
      messagingType: params.messagingType || null,
    });
  }, []);

  const messagingTypeValue =
    configs.messagingType ||
    firebase.libraries.messaging.MESSAGING_TYPES.CHATTING;

  const [stateKey, filterParams] = useMemo(
    () => [
      buildStateKey({
        messagingType: messagingTypeValue,
        firebaseRoomId: configs.firebaseRoomId,
        firebaseThreadId: configs.firebaseThreadId,
      }),
      {
        firebaseRoomId: configs.firebaseRoomId,
        firebaseThreadId: configs.firebaseThreadId,
        itemsPerPage: configs.itemsPerPage,
      },
    ],
    [messagingTypeValue, configs]
  );

  const messaging = messagingHooks[messagingTypeValue];

  const {
    messages: newMessages,
    totalCount,
    loadNextPage,
    isLoading,
    loadMessagesByParams,
    invalidateCache,
  } = messaging.useLoadMessages(filterParams);

  const updateState = useCallback((stateKeyToUpdate, mergeState) => {
    setState((oldState) => {
      const isNewElement = !oldState[stateKeyToUpdate];
      const mergedState = mergeState(oldState[stateKeyToUpdate]);

      return {
        ...oldState,
        [stateKeyToUpdate]: {
          ...mergedState,
          isInitialLoading: isNewElement ? true : mergedState.isInitialLoading,
        },
      };
    });
  }, []);

  useEffect(() => {
    updateState(stateKey, (oldState) => ({
      ...oldState,
      isLoading: isLoading,
      loadedCount: newMessages.length,
      totalCount: totalCount,
      messages: newMessages,
      isInitialLoading: false,
    }));
  }, [newMessages, newMessages?.length, isLoading, stateKey]);

  useEffect(() => {
    updateState(stateKey, (oldState) => ({
      isInitialLoading:
        'isInitialLoading' in oldState ? oldState.isInitialLoading : true,
      isLoading: false,
      loadedCount: oldState?.loadedCount || 0,
      totalCount: oldState?.totalCount || 0,
      messages: oldState?.messages || [],
    }));

    loadNextPageMessages(stateKey);
  }, [stateKey]);

  const loadNextPageMessages = useCallback(
    (currentStateKey, callback) => {
      if (!configs.firebaseRoomId) {
        return;
      }

      updateState(currentStateKey, (oldState) => ({
        ...oldState,
        isLoading: true,
      }));

      setTimeout(() => {
        if (loadNextPage) {
          const result = loadNextPage();
          if (result && typeof result.then === 'function') {
            result.then(() => {
              updateState(currentStateKey, (oldState) => ({
                ...oldState,
                isLoading: false,
                isInitialLoading:
                  oldState.messages.length === 0 && oldState.isInitialLoading,
              }));
              if (typeof callback === 'function') {
                callback();
              }
            });
          } else {
            updateState(currentStateKey, (oldState) => ({
              ...oldState,
              isLoading: false,
              isInitialLoading: !oldState
                ? true
                : oldState.messages.length === 0 && oldState.isInitialLoading,
            }));
            if (typeof callback === 'function') {
              callback();
            }
          }
        }
      }, 300);
    },
    [configs.firebaseRoomId, loadNextPage]
  );

  const getByConfigs = useCallback(
    (params) => {
      const currentStateKey = buildStateKey(params);
      const getData = (name, defaultValue) => {
        return state?.[currentStateKey]?.[name] ?? defaultValue;
      };

      return {
        isInitialLoading: getData('isInitialLoading', true),
        isLoading: getData('isLoading', true),
        loadedMessagesCount: getData('loadedCount', 0),
        totalMessagesCount: getData('totalCount', 0),
        messages: getData('messages', []),
        loadNextPageMessages: (...args) =>
          loadNextPageMessages(currentStateKey, ...args),
      };
    },
    [state]
  );

  const reloadLastMessages = useCallback(() => {
    loadMessagesByParams({
      ...filterParams,
      lastPageFirstItem: null,
    });
  }, [filterParams]);

  const reloadAllMessages = useCallback(() => {
    invalidateCache();
    setState({});

    const prevParams = { ...refLastConfigureParams.current };
    configure({});

    setTimeout(() => {
      configure(prevParams);
    }, 200);
  }, [stateKey, invalidateCache]);

  return {
    configure: configure,
    getByConfigs: getByConfigs,
    reloadLastMessages: reloadLastMessages,
    reloadAllMessages: reloadAllMessages,
  };
}
