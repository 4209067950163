import React from 'react';
import { Flex, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

function CustomerSupportSkeleton() {
  return (
    <Flex gap={4}>
      <SkeletonCircle size={10} startColor="grey.300" endColor="grey.100" />
      <SkeletonText
        w={40}
        noOfLines={3}
        height={3}
        borderRadius={4}
        startColor="grey.300"
        endColor="grey.100"
      />
    </Flex>
  );
}

function ChannelSkeleton() {
  return (
    <Flex
      w="full"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <SkeletonText
        w="90%"
        height={3}
        noOfLines={1}
        borderRadius={4}
        startColor="grey.300"
        endColor="grey.100"
      />
      <SkeletonText
        w={4}
        height={3}
        noOfLines={1}
        borderRadius={4}
        startColor="grey.300"
        endColor="grey.100"
      />
    </Flex>
  );
}

function ThreadSkeleton({ roomType }) {
  const threadCount = Array.from({ length: 5 }, (_, index) => index + 1);

  return (
    <Flex flex={1} flexDirection="column" gap={4} px={1}>
      {threadCount.map((item) => (
        <Flex
          key={`thread-skeleton-${item}`}
          flexDirection="column"
          alignItems="flex-start"
          gap={3}
          pb={2}
        >
          {roomType?.isCustomerSupport && <CustomerSupportSkeleton />}
          {roomType?.isChannel && <ChannelSkeleton />}
          <Skeleton
            w="full"
            height={9}
            borderRadius="full"
            startColor="grey.300"
            endColor="grey.100"
          />
        </Flex>
      ))}
    </Flex>
  );
}

export default ThreadSkeleton;
