import { branding } from './defines';
import { configure, mergeJsonData } from './helpers';

/**
 * @param {keyof import('./types/branding').BrandingInterface['features']} featureName
 * @returns {boolean}
 */
export function feature(featureName) {
  return !!branding?.features?.[featureName];
}

export function initiate({
  branding: appBranding,
  mobileApp: appMobileApp,
  theming: appTheming,
  contactsAndLinks: appContactsAndLinks,
  contentPrivacyPolicy: appContentPrivacyPolicy,
  contentTermsConditions: appContentTermsConditions,
  contentDeliveryPolicy: appContentDeliveryPolicy,
  contentReturnPolicy: appContentReturnPolicy,
}) {
  mergeJsonData({
    branding: appBranding,
    mobileApp: appMobileApp,
    theming: appTheming,
    contactsAndLinks: appContactsAndLinks,
    contentPrivacyPolicy: appContentPrivacyPolicy,
    contentTermsConditions: appContentTermsConditions,
    contentDeliveryPolicy: appContentDeliveryPolicy,
    contentReturnPolicy: appContentReturnPolicy,
  });

  configure({
    branding: appBranding,
  });
}
