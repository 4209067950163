import { MAX_FILE_SIZE_MB } from '../core/constants';

export const sortCategoriesByPriority = (sub1, sub2) =>
  sub2.priority - sub1.priority;

export const sanitizeArrayByField = (array, field) => {
  const response = {};
  array.forEach((row) => (response[row[field]] = row));
  return response;
};

export const stringifyObjectValuesIfObject = (obj) => {
  const response = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      response[key] = JSON.stringify(obj[key]);
    } else {
      response[key] = obj[key];
    }
  }

  return response;
};

export const removeObjectPropertyIfValueLengthExceedsLimit = (obj, limit) => {
  const response = {};
  for (const key in obj) {
    if (obj[key]?.length <= limit) {
      response[key] = obj[key];
    }
  }
  return response;
};

export const reduceCartsTotal = (cartItems) => {
  if (!Array.isArray(cartItems) || cartItems.length === 0) {
    return [0, 0, 0];
  }

  return cartItems.reduce(
    ([totalCost, totalDiscountedCost, totalProductItemsCount], item) => {
      const { price, discountedPrice } = item.productDetail || {};
      return [
        totalCost + item.quantity * price,
        totalDiscountedCost + item.quantity * (discountedPrice || price),
        totalProductItemsCount + item.quantity,
      ];
    },
    [0, 0, 0]
  );
};

export const reduceCartsTotalProductItemsCount = (cartItems) => {
  if (!cartItems) {
    return 0;
  }

  return cartItems.reduce((total, item) => {
    return total + item.quantity;
  }, 0);
};

export const sanitizeCartItems = (cartItems) => {
  const response = {};

  cartItems.forEach((cartItem) => {
    if (cartItem.product.isSingle) {
      response[cartItem.productDetail.id] = cartItem;
    }
  });

  return response;
};

export const fetchBlobFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      resolve(xhr.response);
    };

    xhr.onerror = function () {
      reject(new Error('uriToBlob failed'));
    };

    xhr.responseType = 'blob';

    xhr.open('GET', url, true);

    xhr.send(null);
  });
};

/**
 * @param {File} file
 * @param {{ limit?: number; extensions?: null | Array<string>; }} validationParams
 * @param {{ limit?: string; extensions?: string; }} errorMessages
 * @returns {null|Array<string>}
 */
export function validateFile(file, validationParams = {}, errorMessages = {}) {
  const errors = [];

  validationParams.limit = validationParams.limit || MAX_FILE_SIZE_MB;
  validationParams.extensions = validationParams.extensions || null;

  // validate file size
  const fileSizeInBytes = file.size;
  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
  if (fileSizeInMB > validationParams.limit) {
    if (!errorMessages.limit) {
      errorMessages.limit = `File size must not exceed ${validationParams.limit}MB`;
    }

    errors.push(errorMessages.limit);
  }

  // validate file extension
  if (
    validationParams.extensions &&
    Array.isArray(validationParams.extensions)
  ) {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    if (!validationParams.extensions.includes(fileExtension)) {
      if (!errorMessages.extensions) {
        const extensions = validationParams.extensions || [];
        const allowedExtensions = extensions
          .map((extension) => extension.toUpperCase())
          .join(', ');

        errorMessages.extensions = `Invalid file extension, allowed ${allowedExtensions} only.`;
      }

      errors.push(errorMessages.extensions);
    }
  }

  return errors?.length === 0 ? null : errors;
}

/**
 * @param {File} file
 * @param {{ limit?: number; extensions?: null | Array<string>; }} validationParams
 * @param {{ limit?: string; extensions?: string; }} errorMessages
 */
export async function buildBinaryFile(
  file,
  validationParams = {},
  errorMessages = {}
) {
  const validationErrorMessage = validateFile(
    file,
    validationParams,
    errorMessages
  );

  if (validationErrorMessage) {
    throw new Error(validationErrorMessage);
  }

  if (file.uri && typeof file.uri === 'string') {
    const blob = await fetchBlobFromUrl(file.uri);

    const fileName = blob.data.name;
    const extension = fileName.split('.').pop().toLocaleLowerCase();

    return {
      binary: blob,
      name: fileName,
      extension: extension,
    };
  }

  const fileName = file.name;
  const extension = fileName.split('.').pop().toLocaleLowerCase();

  return {
    binary: file,
    name: fileName,
    extension: extension,
  };
}
