import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import enums from '@happylife-a/enums';
import firebase from '@happylife-a/firebase';
import * as messagingHooks from '../../core/hooks/messagingHooks';
import { useUser } from '../UserContext';
import * as chatRoomCache from './chatRoomCache';

function useIsForAdminCustomerSupport({ messagingType, type }) {
  const { user } = useUser();
  const lib = firebase.libraries.messaging;

  return (
    user?.role === enums.user.UserRoleEnum.ADMIN &&
    type === lib[messagingType]?.constants?.CHAT_ROOM_TYPE_DIRECT &&
    messagingType === lib.MESSAGING_TYPES.CUSTOMER_SUPPORT
  );
}

function getLastTime(item) {
  return new Date(item.lastMessageTime || item.createdAt).getTime();
}

function orderUniqueList(uniqueList) {
  uniqueList.sort((item1, item2) => {
    const diff = getLastTime(item2) - getLastTime(item1);
    if (diff === 0) {
      return 0;
    } else if (diff > 0) {
      return 1;
    } else {
      return -1;
    }
  });
}

function buildFilterParams({ chatRoomType, messagingType }) {
  const filterParams = {};
  if (chatRoomType) {
    filterParams.type = chatRoomType;
  }
  if (messagingType) {
    filterParams.messagingType = messagingType;
  }

  filterParams.orderBy = {
    field: 'lastMessageTime',
    direction: 'desc',
  };

  return filterParams;
}

function buildStateKey({ chatRoomType, messagingType, userId }) {
  return [
    `ChatRoomType:${chatRoomType || 'unknown'}`,
    `MessagingType:${messagingType || 'unknown'}`,
    `UserId:${userId || 'unknown'}`,
  ].join('/');
}

export function useLoadedChatRooms() {
  const { registerSocketEvents } = messagingHooks.common;
  const [hasCustomerSupport, setHasCustomerSupport] = useState(false);
  const { user } = useUser();

  const [state, setState] = useState({});
  const [configs, setConfigs] = useState({
    chatRoomType: null,
    messagingType: null,
  });

  const refLoaded = useRef(false);
  useEffect(() => {
    if (refLoaded.current) {
      chatRoomCache.saveState(state);
    }
  }, [state]);

  // @TODO: temporary disable chat room caching
  // useEffect(() => {
  //   chatRoomCache.loadState().then((cachedState) => {
  //     setState((oldState) => {
  //       const keys = Object.keys(oldState);
  //       const firstKey = buildStateKey({
  //         chatRoomType: null,
  //         messagingType: null,
  //         userId: null,
  //       });

  //       if (
  //         keys.length === 0 ||
  //         (keys.length === 1 &&
  //           keys.includes(firstKey) &&
  //           oldState[firstKey].chatRooms.length === 0)
  //       ) {
  //         return cachedState;
  //       }
  //       return oldState;
  //     });

  //     refLoaded.current = true;
  //   });
  // }, []);

  const [stateKey, filterParams] = useMemo(
    () => [
      buildStateKey({
        chatRoomType: configs.chatRoomType,
        messagingType: configs.messagingType,
        userId: user?.id,
      }),
      buildFilterParams({
        chatRoomType: configs.chatRoomType,
        messagingType: configs.messagingType,
      }),
    ],
    [user?.id, configs.chatRoomType, configs.messagingType]
  );

  const resultForUserChatRooms = messagingHooks.common.useGetChatRooms(
    filterParams,
    { enabled: false }
  );

  const resultForAdminCustomerSupport =
    messagingHooks.common.useGetCustomerSupportRoomsFromAdmin({
      enabled: false,
    });

  const isAdminCustomerSupport = useIsForAdminCustomerSupport(filterParams);
  const refResult = useRef(null);

  refResult.current = isAdminCustomerSupport
    ? resultForAdminCustomerSupport
    : resultForUserChatRooms;

  const {
    data: response,
    refetch: refreshChatRooms,
    remove: removeCached,
    onChatRoomCreate,
    onChatRoomUpdate,
    onChatRoomDelete,
  } = refResult.current;

  const refLastConfigureParams = useRef();
  const configure = useCallback(
    (params) => {
      refLastConfigureParams.current = params;
      setConfigs({
        chatRoomType: params.chatRoomType || null,
        messagingType: params.messagingType || null,
      });

      // @INFO: queue the request until state update.
      const timer = setTimeout(() => {
        refreshChatRooms();
      }, 100);

      return () => {
        clearTimeout(timer);
      };
    },
    [refreshChatRooms]
  );

  const configureEvents = useCallback(() => {
    registerSocketEvents({
      onChatRoomCreate: (data) => onChatRoomCreate(data.chatRoom),
      onChatRoomUpdate: (data) => onChatRoomUpdate(data.chatRoom),
      onChatRoomDelete: (data) => onChatRoomDelete(data.chatRoom),
    });
  }, []);

  useEffect(() => {
    setState((oldState) => {
      if (!oldState[stateKey]) {
        configureEvents();
        loadNextChatRooms();

        return {
          ...oldState,
          [stateKey]: {
            isInitialLoading: true,
            isLoading: true,
            chatRooms: [],
            total: 0,
          },
        };
      }

      return oldState;
    });
  }, [stateKey]);

  const loadNextChatRooms = useCallback(
    (currentStateKey) => {
      if (
        typeof response?.total !== 'number' ||
        state[currentStateKey].chatRooms.length === response.total
      ) {
        return;
      }

      setState((oldState) => ({
        ...oldState,
        [currentStateKey]: {
          ...oldState[currentStateKey],
        },
      }));

      // @TODO: load next page chat rooms
      refreshChatRooms();
    },
    [state, response?.total]
  );

  useEffect(() => {
    if (!response?.chatRooms) {
      return;
    }

    setState((oldState) => {
      const newList = [
        ...(oldState[stateKey]?.chatRooms || []),
        ...response.chatRooms,
      ];
      const uniqueList = [
        ...new Map(newList.map((item) => [item.id, item])).values(),
      ];

      orderUniqueList(uniqueList);
      return {
        ...oldState,
        [stateKey]: {
          isInitialLoading: false,
          isLoading: false,
          total: response.meta.total,
          chatRooms: uniqueList,
        },
      };
    });
  }, [response?.chatRooms, stateKey]);

  useEffect(() => {
    if (!Array.isArray(state[stateKey]?.chatRooms)) {
      return;
    }

    setHasCustomerSupport(
      state[stateKey].chatRooms.some(
        (chatRoom) =>
          chatRoom.messagingType ===
          firebase.libraries.messaging.MESSAGING_TYPES.CUSTOMER_SUPPORT
      )
    );
  }, [state, stateKey]);

  const getByConfigs = useCallback(
    (params) => {
      const currentStateKey = buildStateKey({ ...params, userId: user?.id });
      return {
        isLoading: state?.[currentStateKey]?.isLoading || false,
        isInitialLoading: state?.[currentStateKey]?.isInitialLoading || false,
        chatRooms: state?.[currentStateKey]?.chatRooms || [],
        total: state?.[currentStateKey]?.total || 0,
        loadNextChatRooms: (...args) =>
          loadNextChatRooms(currentStateKey, ...args),
        refreshChatRooms: refreshChatRooms,
      };
    },
    [state, user?.id]
  );

  const clearChatRoomCache = useCallback(async () => {
    removeCached();
    setState({});
    await chatRoomCache.clearState();
  }, []);

  const reloadAllChatRooms = useCallback(() => {
    setState((oldState) => {
      const newState = { ...oldState };
      delete newState[stateKey];
      return newState;
    });

    const params = refLastConfigureParams.current;
    configure({});
    setTimeout(() => {
      configure(params);
    }, 10);
  }, [stateKey]);

  return {
    hasCustomerSupport: hasCustomerSupport,
    configure: configure,
    getByConfigs: getByConfigs,
    clearCache: clearChatRoomCache,
    reloadAllChatRooms: reloadAllChatRooms,
  };
}
