import { useState, useEffect } from 'react';

const useHeightAdjustment = (roomTypes, products, isImageSelected, reply) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (roomTypes.isChannel || roomTypes.isGroup) {
      setHeight(147);
    } else if (roomTypes.isCustomerSupport) {
      setHeight(92);
    } else if (roomTypes.isDirect) {
      setHeight(125);
    }

    if ((roomTypes.isChannel || roomTypes.isGroup) && products?.length > 0) {
      setHeight(250);
    }

    if ((roomTypes.isChannel || roomTypes.isGroup) && isImageSelected) {
      setHeight(310);
    }
    if (roomTypes.isDirect && isImageSelected) {
      setHeight(290);
    }

    if (roomTypes.isDirect && products?.length > 0) {
      setHeight(340);
    }

    if (roomTypes.isDirect && reply) {
      setHeight(193);
    }

    if (roomTypes.isGroup && reply) {
      setHeight(213);
    }
  }, [roomTypes, reply, products, isImageSelected]);

  return height;
};

export default useHeightAdjustment;
