import { useEffect } from 'react';

const useDisableBodyScroll = ({ isDisabled, screenWidthCondition }) => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    const htmlElement = document.documentElement;

    if (isDisabled && !screenWidthCondition) {
      htmlElement.style.setProperty('overflow', 'hidden', 'important');
      document.body.style.setProperty('overflow', 'hidden', 'important');
      if (rootElement) {
        rootElement.style.setProperty('overflow', 'hidden', 'important');
      }
    } else {
      htmlElement.style.setProperty('overflow', 'auto', 'important');
      document.body.style.setProperty('overflow', 'auto', 'important');
      if (rootElement) {
        rootElement.style.setProperty('overflow', 'auto', 'important');
      }
    }

    return () => {
      htmlElement.style.removeProperty('overflow');
      document.body.style.removeProperty('overflow');
      if (rootElement) {
        rootElement.style.removeProperty('overflow');
      }
    };
  }, [isDisabled, screenWidthCondition]);
};

export default useDisableBodyScroll;
