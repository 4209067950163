import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';

const SignInModal = webCore.helpers.dynamic(
  () => import('~components/AuthModal/SignIn')
);
const SignUpModal = webCore.helpers.dynamic(
  () => import('~components/AuthModal/SignUp')
);
const ConfirmSignUpModal = webCore.helpers.dynamic(
  () => import('~components/AuthModal/ConfirmSignUp')
);
const ForgotPasswordModal = webCore.helpers.dynamic(
  () => import('~components/AuthModal/ForgotPassword')
);
const ResetPasswordModal = webCore.helpers.dynamic(
  () => import('~components/AuthModal/ResetPassword')
);
const ManagePickupAddressesModal = webCore.helpers.dynamic(
  () => import('~components/Profile/ManageMainPickupAddressModal')
);

const ProductQuickViewModal = webCore.helpers.dynamic(
  () => import('~components/ProductQuickViewModal')
);
const LogOutModal = webCore.helpers.dynamic(
  () => import('~components/LogOutModal')
);
const EditProfileModal = webCore.helpers.dynamic(
  () => import('~components/Profile/EditProfileModal')
);
const AddEditDeliveryAddressModal = webCore.helpers.dynamic(
  () => import('~components/Profile/AddEditDeliveryAddressModal')
);
const DeliveryAddressDetailsFormModal = webCore.helpers.dynamic(
  () => import('~components/Profile/DeliveryAddressDetailsFormModal')
);
const ManageDeliveryAddressesModal = webCore.helpers.dynamic(
  () => import('~components/ManageDeliveryAddressesModal')
);
const PickupAddressModal = webCore.helpers.dynamic(
  () => import('~components/PickupAddressModal')
);

const DeletePaymentMethodModal = webCore.helpers.dynamic(
  () => import('~components/Profile/PaymentMethods/DeletePaymentMethodModal')
);
const PaymentMethodsModal = webCore.helpers.dynamic(
  () => import('~components/ShoppingCart/PaymentMethodsModal')
);

const ViewDeliveryAddressModal = webCore.helpers.dynamic(
  () => import('~components/Profile/ViewDeliveryAddressModal')
);

const ViewPickupAddressModal = webCore.helpers.dynamic(
  () => import('~components/Profile/ViewPickupAddressModal')
);
const ReviewProductModal = webCore.helpers.dynamic(
  () => import('~components/ReviewProductModal')
);

const AddNewCollectionsModal = webCore.helpers.dynamic(
  () => import('~components/Collections/AddNewCollectionsModal')
);

const UsersSharedModal = webCore.helpers.dynamic(
  () => import('~components/CollectionCard/UsersSharedModal')
);

const ProductAddCollectionModal = webCore.helpers.dynamic(
  () => import('~components/ProductAddCollectionModal')
);

const CustomerTrackOrderModal = webCore.helpers.dynamic(
  () => import('~components/OrderHistory/CustomerTrackOrderModal')
);

const CreateChatModal = webCore.helpers.dynamic(
  () => import('~components/Chat/CreateChatModal')
);

const ForwardModal = webCore.helpers.dynamic(
  () => import('~components/Chat/ForwardModal')
);

const DeleteMessageModal = webCore.helpers.dynamic(
  () => import('~components/Chat/DeleteMessageModal')
);

const AddPickupAddressNameSurnameModal = webCore.helpers.dynamic(
  () => import('~components/Profile/AddPickupAddressNameSurnameModal')
);

const DeleteAccountModal = webCore.helpers.dynamic(
  () => import('~components/Profile/DeleteAccountModal')
);

const MembersModal = webCore.helpers.dynamic(
  () => import('~components/Chat/Groups/MembersModal')
);

const NewGroupModal = webCore.helpers.dynamic(
  () => import('~components/Chat/Groups/NewGroupModal')
);

const CreateChannelModal = webCore.helpers.dynamic(
  () => import('~components/Chat/Channels/CreateChannelModal')
);

const MuteNotificationsModal = webCore.helpers.dynamic(
  () => import('~components/Chat/MuteNotificationsModal')
);

const ChannelDeleteModal = webCore.helpers.dynamic(
  () => import('~components/Chat/Channels/ChannelDeleteModal')
);

const ProductShareViewModal = webCore.helpers.dynamic(
  () => import('~components/ProductShareViewModal')
);

const ShareCollectionModal = webCore.helpers.dynamic(
  () => import('~components/CollectionCard/ShareCollectionModal')
);

const ProductsModal = webCore.helpers.dynamic(
  () => import('~components/Chat/ProductsModal')
);

const SubjectsModal = webCore.helpers.dynamic(
  () => import('~components/CustomerSupport/SubjectsModal')
);

const CreateThreadModal = webCore.helpers.dynamic(
  () => import('~components/CustomerSupport/CreateThreadModal')
);

const AppDownloadModal = webCore.helpers.dynamic(
  () => import('~components/AppDownloadModal')
);

const modals = {
  [webApp.constants.ENUM_MODAL_SIGN_IN]: SignInModal,
  [webApp.constants.ENUM_MODAL_SIGN_UP]: SignUpModal,
  [webApp.constants.ENUM_MODAL_CONFIRM_SIGN_UP]: ConfirmSignUpModal,
  [webApp.constants.ENUM_MODAL_FORGOT_PASSWORD]: ForgotPasswordModal,
  [webApp.constants.ENUM_MODAL_RESET_PASSWORD]: ResetPasswordModal,
  [webApp.constants.ENUM_MODAL_QUICK_VIEW]: ProductQuickViewModal,
  [webApp.constants.ENUM_MODAL_LOG_OUT]: LogOutModal,
  [webApp.constants.ENUM_MODAL_EDIT_PROFILE]: EditProfileModal,
  [webApp.constants.ENUM_MODAL_ADD_EDIT_DELIVERY_ADDRESS]:
    AddEditDeliveryAddressModal,
  [webApp.constants.ENUM_MODAL_DELIVERY_ADDRESS_DETAILS_FORM]:
    DeliveryAddressDetailsFormModal,
  [webApp.constants.ENUM_MODAL_MANAGE_DELIVERY_ADDRESSES]:
    ManageDeliveryAddressesModal,
  [webApp.constants.ENUM_MODAL_ADD_EDIT_PICKUP_ADDRESS]: PickupAddressModal,
  [webApp.constants.ENUM_MODAL_MANAGE_PICKUP_ADDRESSES]:
    ManagePickupAddressesModal,
  [webApp.constants.ENUM_MODAL_DELETE_PAYMENT_METHOD]: DeletePaymentMethodModal,
  [webApp.constants.ENUM_MODAL_CHOOSE_PAYMENT_METHODS]: PaymentMethodsModal,
  [webApp.constants.ENUM_MODAL_PROFILE_VIEW_DELIVERY_ADDRESS]:
    ViewDeliveryAddressModal,
  [webApp.constants.ENUM_MODAL_PROFILE_VIEW_PICKUP_ADDRESS]:
    ViewPickupAddressModal,
  [webApp.constants.ENUM_MODAL_REVIEW_PRODUCT]: ReviewProductModal,
  [webApp.constants.ENUM_MODAL_ADD_NEW_COLLECTIONS]: AddNewCollectionsModal,
  [webApp.constants.ENUM_MODAL_USERS_SHARED]: UsersSharedModal,
  [webApp.constants.ENUM_MODAL_PRODUCT_ADD_COLLECTION]:
    ProductAddCollectionModal,
  [webApp.constants.ENUM_MODAL_CUSTOMER_TRACK_ORDER]: CustomerTrackOrderModal,
  [webApp.constants.ENUM_MODAL_CREATE_CHAT]: CreateChatModal,
  [webApp.constants.ENUM_MODAL_FORWARD_MESSAGE]: ForwardModal,
  [webApp.constants.ENUM_MODAL_DELETE_MESSAGE]: DeleteMessageModal,
  [webApp.constants.ENUM_MODAL_PICKUP_ADDRESS_NAME_SURNAME]:
    AddPickupAddressNameSurnameModal,
  [webApp.constants.ENUM_MODAL_DELETE_ACCOUNT]: DeleteAccountModal,

  [webApp.constants.ENUM_MODAL_MEMBERS]: MembersModal,
  [webApp.constants.ENUM_MODAL_NEW_GROUP]: NewGroupModal,
  [webApp.constants.ENUM_MODAL_CREATE_CHANNEL]: CreateChannelModal,
  [webApp.constants.ENUM_MODAL_MUTE_NOTIFICATIONS]: MuteNotificationsModal,
  [webApp.constants.ENUM_MODAL_CHANNEL_DELETE]: ChannelDeleteModal,
  [webApp.constants.ENUM_MODAL_SHARE_PRODUCT]: ProductShareViewModal,
  [webApp.constants.ENUM_MODAL_SHARE_COLLECTION]: ShareCollectionModal,
  [webApp.constants.ENUM_MODAL_PRODUCTS]: ProductsModal,
  [webApp.constants.ENUM_MODAL_CS_SUBJECTS]: SubjectsModal,
  [webApp.constants.ENUM_MODAL_CREATE_THREAD]: CreateThreadModal,
  [webApp.constants.ENUM_MODAL_APP_DOWNLOAD]: AppDownloadModal,
};

export default modals;
