import React, { useCallback, useMemo } from 'react';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { useLocalizedNavigate } from '../../../../../components/LocalizedLink';
import NavigationCancelModal from './NavigationCancelModal';

function Navigation({
  components,
  index,
  setIndex,
  onSubmit,
  form,
  validateSection,
  isLoading,
  isSingle,
}) {
  const pages = components.length;

  const { t } = webCore.contexts.useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useLocalizedNavigate();

  const sectionValues = form.watch(validateSection);
  const hasErrors =
    !!form.formState.errors?.[validateSection] || !sectionValues;

  const onPrevClick = useCallback(
    (e) => {
      if (typeof setIndex === 'function') {
        setIndex((oldIndex) => Math.max(oldIndex - 1, 0));
      }
    },
    [setIndex]
  );

  const onNextClick = useCallback(
    async (e) => {
      const isValid = await form.trigger(validateSection);
      if (!isValid) {
        utils.helpers.logging.error(
          'form validation error:',
          form.formState.errors
        );
        return;
      }

      if (components[index] === 'FormDefinitionNonMandatory') {
        if (typeof onSubmit === 'function') {
          onSubmit(e);
        }

        return;
      }

      if (typeof setIndex === 'function') {
        setIndex((oldIndex) => Math.min(oldIndex + 1, pages - 1));
      }
    },
    [pages, components, onSubmit, setIndex, form, validateSection]
  );

  const selectedImages = form.watch('MainDetails.images.selected');
  const chosenFiles = form.watch('MainDetails._files');
  const hasFiles =
    chosenFiles !== undefined &&
    Array.isArray(chosenFiles) &&
    chosenFiles.length > 0;

  const onContinue = useCallback(() => {
    navigate('/');
  }, []);

  const hasErroredFiles = (chosenFiles || []).some(
    (chosenFile) => chosenFile?.errors?.length > 0
  );
  const filesStillUploading = form.watch('MainDetails._filesUploading');
  const termsAreAccepted = form.watch('FormDefinition.terms');
  const variations = form.watch('MainDetails.variation') || [];

  const variationsCount = variations.length;
  const selectedImagesCount = Object.values(selectedImages || {}).filter(
    (selectedImageUrls) => (selectedImageUrls || []).length > 0
  ).length;

  // @INFO: uncomment 3rd parameter check condition if sub-sub-category is required
  const isCategoriesValid = [
    form.watch('GeneralInfo._cat.parentCategoryId'),
    form.watch('GeneralInfo._cat.subCategoryId'),
    // form.watch('GeneralInfo._cat.subSubCategoryId'),
  ]
    .map(Boolean)
    .reduce((acc, value) => acc && value, true);

  const isNextButtonDisabled = useMemo(() => {
    // is terms and conditions checked in last page
    if (
      termsAreAccepted === false && // in case of undefined we need to keep button enabled
      components[index] === 'FormDefinitionNonMandatory'
    ) {
      return true;
    }

    // if has error on some files or files still uploading
    if (hasErroredFiles || filesStillUploading) {
      return true;
    }

    if (components[index] === 'MainDetails') {
      if (!hasFiles) {
        return true;
      }
      if (!isSingle) {
        if (typeof selectedImages === 'undefined') {
          return true;
        }
        if (selectedImagesCount !== variationsCount) {
          return true;
        }
      }
    }

    // category/sub-category/sub-sub-category required
    if (!isCategoriesValid) {
      return true;
    }

    // sectionValues?.title?.en === '' || TODO
    return isLoading || hasErrors;
  }, [
    isSingle,
    isLoading,
    selectedImages,
    selectedImagesCount,
    variationsCount,
    hasFiles,
    hasErrors,
    hasErroredFiles,
    filesStillUploading,
    isCategoriesValid,
    termsAreAccepted,
    index,
    components,
  ]);

  return (
    <ButtonGroup gap="2">
      <Button
        variant="defaultBig"
        height="44px"
        cursor="pointer"
        width="180px"
        color="red.500"
        borderColor="red.500"
        borderWidth="2px"
        borderRadius="12px"
        isDisabled={isLoading}
        onClick={onOpen}
      >
        {t('AppCancel')}
      </Button>

      <NavigationCancelModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onContinue}
      />

      {index > 0 && (
        <Button
          variant="outline"
          height="44px"
          cursor="pointer"
          width="180px"
          color="primary.500"
          borderColor="primary.500"
          borderWidth="2px"
          borderRadius="12px"
          onClick={onPrevClick}
          isDisabled={isLoading}
        >
          {t('AppBack')}
        </Button>
      )}

      <Button
        variant="darkPrimary"
        cursor="pointer"
        width="180px"
        height="44px"
        borderRadius="12px"
        isLoading={isLoading}
        isDisabled={isNextButtonDisabled}
        onClick={onNextClick}
      >
        {index === pages - 1 ? t('AppCreate') : t('AppNext')}
      </Button>
    </ButtonGroup>
  );
}

export default Navigation;
