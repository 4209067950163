import { useEffect, useState } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webCore from '@happylife-a/web-core';

export default function useChatRoom({ initialChatRoom = null }) {
  const { user } = webCore.contexts.useUser();
  const [chatRoomParams, setChatRoomParams] = useState(null);

  const checkRoomType = webCore.hooks.chat.useCheckRoomType();
  const getDirectFriend = webCore.hooks.chat.useGetDirectFriend();
  const getRoomName = webCore.hooks.chat.useGetRoomName();

  useEffect(() => {
    const fetchChatRoomParams = async () => {
      if (initialChatRoom) {
        const chatRoom = initialChatRoom;

        const roomName = getRoomName(chatRoom);
        let avatarUrl = '';

        if (!checkRoomType(chatRoom).isDirect) {
          avatarUrl = chatRoom?.avatarKey
            ? s3Storage.helpers.read.buildUrlChatRoomAvatar(chatRoom.avatarKey)
            : '';
        } else {
          const friend = getDirectFriend(chatRoom);
          avatarUrl = friend?.avatarKey
            ? s3Storage.helpers.read.buildUrlProfileAvatar(friend.avatarKey)
            : '';
        }

        const params = {
          chatRoom: {
            id: chatRoom.firebaseRoomId,
            ownerUserId: chatRoom.owner.userId,
            ownerChatUserId: chatRoom.owner.id,
            firebaseRoomId: chatRoom.firebaseRoomId,
            roomName: roomName,
            avatarUrl: avatarUrl,
            type: chatRoom.type,
            messagingType: chatRoom.messagingType,
            description: chatRoom.description,
            color: chatRoom.color || null,
            lastMessageTime: chatRoom.lastMessageTime,
            lastMessage: chatRoom.lastMessage,
            membersCount: chatRoom.membersCount,
            directUser1: chatRoom.directUser1,
            directUser2: chatRoom.directUser2,
          },
          firebaseRoomId: chatRoom.firebaseRoomId,
        };

        setChatRoomParams(params);
      }
    };

    setChatRoomParams(null);

    setTimeout(() => {
      fetchChatRoomParams();
    }, 10);
  }, [initialChatRoom?.id, user]);

  return chatRoomParams;
}
