import webCore from '@happylife-a/web-core';

const MainLayout = webCore.helpers.dynamic(() => import('~layouts/Main'));

// become seller
const BecomeSeller = webCore.helpers.dynamic(
  () => import('~pages/become-seller/index')
);

// register
const RegisteredSuccessfully = webCore.helpers.dynamic(
  () => import('~pages/become-seller/success')
);
const RegisteredVerification = webCore.helpers.dynamic(
  () => import('~pages/become-seller/verification')
);

const UserLogin = webCore.helpers.dynamic(() => import('~pages/user/sign-in'));
const UserSignup = webCore.helpers.dynamic(() => import('~pages/user/sign-up'));

const screens = [
  // become seller
  {
    pathname: '/become-seller',
    screen: BecomeSeller,
    layout: MainLayout,
  },
  {
    pathname: '/become-seller/verification',
    screen: RegisteredVerification,
    layout: MainLayout,
  },
  {
    pathname: '/become-seller/success',
    screen: RegisteredSuccessfully,
    layout: MainLayout,
  },
  //sign-actions
  {
    pathname: '/user/sign-in',
    screen: UserLogin,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
  {
    pathname: '/user/sign-up',
    screen: UserSignup,
    layout: MainLayout,
    layoutParams: {
      showMobileSearch: false,
    },
  },
];

export default screens;
