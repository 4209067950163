import React from 'react';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function ThreadDetails({ details }) {
  const { t } = webCore.contexts.useTranslation();
  const images = details?.medias || [];

  return (
    <Flex
      flexDir="column"
      gap={3}
      pt={5}
      px={4}
      borderTopWidth={1}
      borderTopColor="grey.600"
      cursor="pointer"
    >
      {images && images.length > 0 && (
        <webApp.components.ImageMessage images={images} />
      )}
      <Text variant="sub-head-reg">{details?.message?.title}</Text>
      <Flex mt={2} justifyContent="center">
        <Text variant="sub-head-reg" color="grey.600">
          {t('Comments')}
        </Text>
      </Flex>
    </Flex>
  );
}
