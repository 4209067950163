import appBranding from '@happylife-a/branding';
import { locales as availableLocales } from './locales';

let translationsMap = {};
export function mergeTranslationsMap(newTranslationsMap) {
  translationsMap = {
    ...translationsMap,
    ...newTranslationsMap,
  };
}

function getTranslationByLocale(translationKey, withLocale) {
  try {
    return translationsMap[withLocale][translationKey] || translationKey;
  } catch (e) {
    return translationKey;
  }
}

function injectDataToTranslatedText(translated, data = {}) {
  if (typeof data === 'object') {
    for (const [key, value] of Object.entries(data)) {
      const pattern = new RegExp('{{' + key + '}}', 'g');
      translated = translated.replace(pattern, value);
    }
  }

  const appBrandName = appBranding.branding.brand?.name;
  return translated?.replace(/\{\{AppBrandName\}\}/g, appBrandName);
}

export function translate(locale, text, data = {}) {
  let result = getTranslationByLocale(text, locale);

  // @INFO: in case of translation not found in provided locale we have
  //        to find it in another locales.
  if (result === text) {
    for (const possibleLocale of availableLocales) {
      const resultInAnotherLocale = getTranslationByLocale(
        text,
        possibleLocale
      );

      if (resultInAnotherLocale) {
        result = resultInAnotherLocale;
        break;
      }
    }
  }

  return injectDataToTranslatedText(result, data);
}
