import React, { useCallback } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  Text,
  Flex,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { ChatMediaAttachIcon, ChatProductIcon } from '../../../theme/icons';
import AttachImages from '../AttachImages';

export default function AttachableMenu({
  selectedImages,
  setSelectedImages,
  onOpenProductsModal,
  setIsUploadingImages,
}) {
  const { t } = webCore.contexts.useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const openProductsModal = useCallback(() => {
    if (typeof onOpenProductsModal === 'function') {
      onOpenProductsModal();
    }
  }, []);
  return (
    <Menu isLazy={true} isOpen={isOpen} onClose={onClose} closeOnBlur={true}>
      <MenuButton
        isActive={isOpen}
        bgColor="transparent"
        p={0}
        autoselect={'false'}
        minWidth="unset"
        _hover={{ bgColor: 'unset' }}
        _active={{ bgColor: 'unset' }}
        as={Button}
        rightIcon={
          <Box onClick={onToggle} pos="relative" right={1}>
            <ChatMediaAttachIcon w={6} h={6} />
          </Box>
        }
      />
      <MenuList
        minWidth={186}
        borderRadius="8px"
        border="none"
        boxShadow="0px 0px 48px 0px rgba(0, 0, 0, 0.04), 0px 8px 24px 0px rgba(0, 0, 0, 0.08);"
      >
        <Flex px={4} py={2} flexDir="column" gap={3}>
          <AttachImages
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            setIsUploadingImages={setIsUploadingImages}
            onClose={onClose}
          />
          {/* <Flex gap={4}>
            <ChatDocumentIcon fill="none" w={6} h={6} />
            <Text>{t('Document')}</Text>
          </Flex> @TODO::need latter */}

          <Flex gap={4} cursor="pointer" onClick={openProductsModal}>
            <ChatProductIcon fill="none" w={6} h={6} />
            <Text>{t('Product')}</Text>
          </Flex>
        </Flex>
      </MenuList>
    </Menu>
  );
}
