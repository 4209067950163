export function randomId(length = 8) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

export function fromList(list, count = 1) {
  const buffer = [];
  for (let i = 0; i < count; ++i) {
    const random = Math.floor(Math.random() * list.length);
    buffer.push(list[random]);
  }

  return buffer;
}

export function uuid() {
  let d = new Date().getTime();
  let d2 =
    (typeof performance !== 'undefined' && performance.now
      ? performance.now() * 1000
      : 0) || 0;

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function numeric(length = 6) {
  let code = '';
  for (let i = 0; i < length; ++i) {
    const randomNumber = Math.floor(Math.random() * 10).toString();
    code += randomNumber.slice(0, 1);
  }

  return code;
}

export function uniqueId(joinVia = '') {
  return [Date.now(), randomId(6), uuid().replace(/-/g, '')].join(joinVia);
}

export function shortUniqueId(joinVia = '') {
  const list = [
    ...fromList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 10),
    ...fromList(['a', 'b', 'c', 'd', 'e', 'f'], 3),
    ...fromList(['A', 'B', 'C', 'D', 'E', 'F'], 3),
    ...fromList(['x', 'y', 'z'], 3),
    ...fromList(['X', 'Y', 'Z'], 3),
  ];

  shuffle(list);
  return [Date.now(), list.join('')].join(joinVia);
}

export function shuffle(list) {
  let index = list.length;

  while (index > 0) {
    const randomIndex = Math.floor(Math.random() * index);
    --index;

    const temp = list[index];
    list[index] = list[randomIndex];
    list[randomIndex] = temp;
  }

  return list;
}

export function shuffleClone(list) {
  const cloneList = list.map((item) => item);
  return shuffle(cloneList);
}
