import React, { useCallback, useEffect, useRef } from 'react';
import webCore from '@happylife-a/web-core';
import {
  Button,
  Input,
  Stack,
  Icon,
  Text,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { theme } from '../../../../../theme';
import SingleImageUploader from './SingleImageUploader';

const CREATE_PRODUCT_IMAGE_ID_NEW = 'create-product-image-new';
const CREATE_PRODUCT_IMAGE_ID_OLD = 'create-product-image-old';

function buildFileKey(file, min = 10000000, max = 99999999) {
  const randomNumber = Math.floor(Math.random() * (max - min) + min);
  const prefix = `${file.name.replace(/\./g, '-')}/${file.size}`;
  return `f/${prefix}/${Date.now()}/${randomNumber}`;
}

export default function ImagesUploader({ form }) {
  const { t } = webCore.contexts.useTranslation();

  const refImageUploads = useRef({ chosen: 0, uploaded: 0 });
  const files = form.watch('MainDetails._files');

  const onChange = useCallback((e) => {
    form.setValue('MainDetails._files', [
      ...(form.getValues()?.MainDetails?._files || []),
      ...[...e.target.files].map((file) => ({
        file: file,
        key: buildFileKey(file),
        errors: [],
        uploading: false,
        uploaded: false,
      })),
    ]);

    e.target.value = null;
  }, []);

  const onUploadStart = useCallback((uploadedFileKey) => {
    const oldFiles = form.getValues()?.MainDetails?._files || [];
    const newFiles = oldFiles.map((file) => {
      if (uploadedFileKey === file.key) {
        file.uploading = true;
      }

      return file;
    }, {});

    refImageUploads.current.chosen += newFiles.length;
    form.setValue('MainDetails._files', newFiles);
  }, []);

  const onUploadEnd = useCallback((uploadedFileKey) => {
    const oldFiles = form.getValues()?.MainDetails?._files || [];
    const newFiles = oldFiles.map((file) => {
      if (uploadedFileKey === file.key) {
        file.uploaded = true;
      }

      return file;
    }, {});

    refImageUploads.current.uploaded += newFiles.length;
    form.setValue('MainDetails._files', newFiles);
  }, []);

  const onRemove = useCallback((removeFileKey) => {
    // remote image
    const oldImages = form.getValues()?.MainDetails?.images?.all || {};
    const newImages = Object.keys(oldImages).reduce((acc, fileKey) => {
      if (removeFileKey !== fileKey) {
        acc[fileKey] = oldImages[fileKey];
      }

      return acc;
    }, {});
    form.setValue('MainDetails.images.all', newImages);

    // remove file
    const oldFiles = form.getValues()?.MainDetails?._files || [];
    const newFiles = oldFiles.filter((file) => {
      return removeFileKey !== file.key;
    }, {});
    form.setValue('MainDetails._files', newFiles);
  }, []);

  const onValidationError = useCallback(
    (invalidFileKey, validationErrorMessages) => {
      const oldFiles = form.getValues()?.MainDetails?._files || [];
      const newFiles = oldFiles.map((file) => {
        if (invalidFileKey === file.key) {
          file.errors = validationErrorMessages;
        }

        return file;
      }, {});

      refImageUploads.current.uploaded += newFiles.length;
      form.setValue('MainDetails._files', newFiles);
    },
    []
  );

  const onChangeImage = (e, file) => {
    const newFiles = e.target.files;
    if (!newFiles || newFiles.length === 0) {
      return;
    }

    const existingFiles = form.getValues('MainDetails._files');
    existingFiles.forEach((existingFile) => {
      if (existingFile.key === file.key) {
        existingFile.file = newFiles[0];
        existingFile.errors = [];
        existingFile.uploading = false;
        existingFile.uploaded = false;
      }
    });

    form.setValue('MainDetails._files', [...existingFiles]);
    e.target.value = null;
  };

  const { chosen, uploaded } = refImageUploads.current;
  useEffect(() => {
    form.setValue('MainDetails._filesUploading', chosen > uploaded);
  }, [chosen, uploaded]);

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6}>
      <GridItem colEnd={1}>
        <Stack spacing={2}>
          <Grid templateColumns="repeat(12, 1fr)" gap={2}>
            {files?.map((file, i) => (
              <GridItem
                key={`CreateSingleProduct-category-${i}-${file.key}`}
                background="white"
                width="100px"
                height="100px"
              >
                <SingleImageUploader
                  form={form}
                  fileKey={file.key}
                  file={file.file}
                  uploading={file.uploading}
                  uploaded={file.uploaded}
                  errors={file.errors}
                  onUploadStart={() => onUploadStart(file.key)}
                  onUploadEnd={() => onUploadEnd(file.key)}
                  onRemove={() => onRemove(file.key)}
                  onValidationError={(errorMessages) =>
                    onValidationError(file.key, errorMessages)
                  }
                  id={`${CREATE_PRODUCT_IMAGE_ID_OLD}/${file.key}`}
                  onChange={(e) => onChangeImage(e, file)}
                />
              </GridItem>
            ))}

            <GridItem>
              <Input
                type="file"
                id={CREATE_PRODUCT_IMAGE_ID_NEW}
                accept="image/*"
                multiple={true}
                display="none"
                onChange={onChange}
              />

              <Button
                as="label"
                variant="link"
                cursor="pointer"
                borderRadius="8px"
                borderColor="grey.600"
                borderWidth={1}
                borderStyle="dashed"
                width="110px"
                height="100px"
                p={2}
                textAlign="center"
                htmlFor={CREATE_PRODUCT_IMAGE_ID_NEW}
                display="flex"
                flexDirection="column"
              >
                <Icon
                  as={theme.icons.FileUpload}
                  fill="none"
                  stroke="primary.500"
                  w={5}
                  h={5}
                  mr={1}
                />
                <Text as="span" variant="body-reg-md" color="primary.500">
                  {t('AppUploadImage')}
                </Text>
              </Button>
            </GridItem>
          </Grid>
        </Stack>
      </GridItem>
    </Grid>
  );
}
