const commonHeaders = {};

export function addCommonHeader(key, value) {
  commonHeaders[key.toUpperCase()] = value;
}

export function removeCommonHeader(key, value) {
  commonHeaders[key.toUpperCase()] = value;
}

export function getCommonHeaders() {
  return commonHeaders;
}

function detectBrowser(ua) {
  let chromeAgent = ua.indexOf('Chrome') > -1;
  const internetExplorerAgent =
    ua.indexOf('MSIE') > -1 || ua.indexOf('rv:') > -1;
  const firefoxAgent = ua.indexOf('Firefox') > -1;
  let safariAgent = ua.indexOf('Safari') > -1;
  const operaAgent = ua.indexOf('OP') > -1;

  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }
  if (chromeAgent && operaAgent) {
    chromeAgent = false;
  }

  if (chromeAgent) {
    return 'Chrome';
  }
  if (firefoxAgent) {
    return 'Firefox';
  }
  if (safariAgent) {
    return 'Safari';
  }
  if (internetExplorerAgent) {
    return 'Internet Explorer';
  }
  if (operaAgent) {
    return 'Opera';
  }
  return 'UnknownBrowser';
}

export function setClientAppName(clientName, platform, version) {
  if (typeof platform !== 'string') {
    if (!platform.ua) {
      platform = JSON.stringify(platform);
    } else {
      platform = detectBrowser(platform.ua);
    }
  }

  addCommonHeader(
    'X-Client-Information',
    `type=${clientName} platform=${platform} version=${version}`
  );
}
