import { useCallback, useEffect, useState } from 'react';
import utils from '@happylife-a/utils';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useUser } from '../../contexts/UserContext';
import {
  REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY,
  REACT_QUERY_GET_FAVORITES_QUERY_KEY,
  REACT_QUERY_GET_INFINITE_FAVORITES_QUERY_KEY,
} from '../constants';
import favoriteUseCase from '../factories/favorite';

const useFavoriteUseCase = () => {
  const { isUnknownUser } = useUser();
  return favoriteUseCase(isUnknownUser);
};

export const useGetFavoritesIds = (options = {}) => {
  const useCase = useFavoriteUseCase();
  const query = useQuery(
    [REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY],
    useCase.getFavoritesIds,
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetFavorites = (options = {}) => {
  const useCase = useFavoriteUseCase();
  const query = useQuery(
    [REACT_QUERY_GET_FAVORITES_QUERY_KEY],
    useCase.getFavorites,
    options
  );

  return {
    ...query,
    data: query.data || {},
  };
};

export const useGetInfiniteFavorites = (variables, options = {}) => {
  const useCase = useFavoriteUseCase();
  const query = useInfiniteQuery(
    [REACT_QUERY_GET_INFINITE_FAVORITES_QUERY_KEY, variables],
    ({ pageParam = 0 }) => {
      if (pageParam === null) {
        return { favorites: [], meta: {} };
      }

      return useCase.getFavorites({
        ...variables,
        offset: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const { offset, limit, total } = lastPage.meta;

        const nextOffset = offset + limit;
        return nextOffset < total ? nextOffset : undefined;
      },
      ...options,
    }
  );

  return query;
};

export const useCreateFavorite = () => {
  const useCase = useFavoriteUseCase();
  const mutation = useMutation(useCase.createFavorite);

  return mutation;
};

export const useDeleteFavorite = () => {
  const useCase = useFavoriteUseCase();
  const mutation = useMutation(useCase.deleteFavorite);

  return mutation;
};

export const useToggleFavorite = (isFavorite) => {
  const { user, setOptimisticUpdate } = useUser();
  const queryClient = useQueryClient();
  const queryKey = [REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY];
  const { mutate: deleteFavorite } = useDeleteFavorite();
  const { mutate: createFavorite } = useCreateFavorite();
  const favorites = queryClient.getQueryData(queryKey);

  const toggleFavorite = useCallback(
    (productDetailId, { onCreate, onDelete } = {}) => {
      if (isFavorite(productDetailId)) {
        const deleteFavoriteInput = {
          productDetailId: productDetailId,
        };

        const onDeleteFavoriteSuccess = () => {
          queryClient.setQueriesData(
            [REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY],
            (data) => {
              const newData = { ...data };
              newData.ids = newData.ids.filter(
                (id) =>
                  !utils.helpers.id.same(id.productDetailId, productDetailId)
              );

              return newData;
            }
          );

          const lastObjectInfo = null;
          queryClient.setQueriesData(
            [REACT_QUERY_GET_INFINITE_FAVORITES_QUERY_KEY],
            (oldData) => {
              const updatedPages = oldData.pages.map((page) => ({
                ...page,
                favorites: page.favorites.filter(
                  (item) =>
                    !utils.helpers.id.same(
                      item.productDetailId,
                      productDetailId
                    )
                ),
              }));

              return {
                ...oldData,
                pages: updatedPages,
              };
            }
          );

          if (typeof onDelete === 'function') {
            onDelete(lastObjectInfo);
          }
        };

        deleteFavorite(deleteFavoriteInput, {
          onSuccess: onDeleteFavoriteSuccess,
        });
      } else {
        const createFavoriteInput = {
          productDetailId: productDetailId,
        };

        const onProductFavoriteSuccess = (response) => {
          const lastObjectInfo = {
            id: response.id,
            productId: response.productId,
            productDetailId: response.productDetailId,
          };

          queryClient.setQueryData(
            [REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY],
            (data) => {
              data = data || { ids: [] };
              return {
                ...data,
                ids: [...data.ids, lastObjectInfo],
              };
            }
          );

          if (typeof onCreate === 'function') {
            onCreate(lastObjectInfo);
          }
        };

        createFavorite(createFavoriteInput, {
          onSuccess: onProductFavoriteSuccess,
        });
      }
    },
    [isFavorite]
  );

  useEffect(() => {
    if (!favorites) {
      return;
    }

    const favoritesCount = favorites.ids?.length || 0;
    setOptimisticUpdate({
      ...user,
      customerInfo: { ...user.customerInfo, favoritesCount: favoritesCount },
    });
  }, [favorites]);

  return toggleFavorite;
};

export const useSelectedItemsSummary = (
  cartItems,
  selectedItems,
  totalCoast,
  totalItems
) => {
  const [total, setTotal] = useState({ cost: totalCoast, items: totalItems });
  useEffect(() => {
    if (selectedItems?.length === 0 || cartItems?.length === 0) {
      setTotal({ cost: 0, items: 0 });
      return;
    }

    const { cost, items } = selectedItems.reduce(
      (acc, itemId) => {
        const item = cartItems?.find((cartItem) =>
          utils.helpers.id.same(cartItem.productDetailId, itemId)
        );

        if (item) {
          const { quantity, productDetail } = item;
          const { discountedPrice, price } = productDetail;

          const effectivePrice = discountedPrice || price;
          acc.cost += effectivePrice * quantity;
          acc.items += quantity;
        }
        return acc;
      },
      { cost: 0, items: 0 }
    );

    setTotal({ cost: cost, items: items });
  }, [cartItems, selectedItems, totalCoast]);

  return total;
};

export const useCreateBulkFavorites = () => {
  const useCase = useFavoriteUseCase();
  const queryClient = useQueryClient();

  const mutation = useMutation(useCase.createBulkFavorites, {
    onSuccess: (data) => {
      const updatedIds = data?.favorites.map((favorite) => ({
        id: favorite.id,
        productId: favorite.productId,
        productDetailId: favorite.productDetailId,
      }));

      queryClient.setQueryData(
        [REACT_QUERY_GET_FAVORITES_IDS_QUERY_KEY],
        (existingData) => {
          existingData = existingData || { ids: [] };
          return {
            ...existingData,
            ids: [...existingData.ids, ...updatedIds],
          };
        }
      );
    },
  });
  return mutation;
};
