import http, { API_CORE } from '../http';

export default function ShoppingCartService(inUnknownUser) {
  const getCartItemsIds = async () => {
    if (!inUnknownUser) {
      return {};
    }

    const response = await http(API_CORE).get('/shopping-carts/ids');
    return response.data;
  };

  const getCartItems = async () => {
    if (!inUnknownUser) {
      return {};
    }

    const response = await http(API_CORE).get('/shopping-carts');
    return response.data;
  };

  const createCart = async (input) => {
    const { productDetailId, quantity } = input;
    const response = await http(API_CORE).post(
      `/shopping-carts/${productDetailId}`,
      {
        quantity: quantity,
      }
    );

    return response.data.shoppingCart;
  };

  const updateCartCount = async (input) => {
    const { id, quantity } = input;
    const response = await http(API_CORE).put(`/shopping-carts/${id}`, {
      quantity: quantity,
    });

    return response.data.shoppingCart;
  };

  const deleteCart = async ({ productDetailId }) => {
    const response = await http(API_CORE).delete(
      `/shopping-carts/${productDetailId}`
    );
    return response.data;
  };

  const bulkDeleteCartItems = async (ids) => {
    const response = await http(API_CORE).delete('/shopping-carts/bulk', {
      data: ids,
    });
    return response.data;
  };

  const calculateCostAndDate = async (params) => {
    const response = await http(API_CORE).get('/shopping-carts/calculate', {
      params: params,
    });
    return response.data;
  };

  const getDeliveryOptions = async ({ productDetailIds }) => {
    const response = await http(API_CORE).get(
      '/shopping-carts/deliver-options',
      {
        params: {
          productDetailIds: productDetailIds,
        },
      }
    );
    return response.data;
  };

  return {
    getCartItemsIds: getCartItemsIds,
    createCart: createCart,
    updateCartCount: updateCartCount,
    deleteCart: deleteCart,
    getCartItems: getCartItems,
    bulkDeleteCartItems: bulkDeleteCartItems,
    calculateCostAndDate: calculateCostAndDate,
    getDeliveryOptions: getDeliveryOptions,
  };
}
