import React from 'react';
import LazyLoad from 'react-lazyload';
import StarRatings from 'react-star-ratings';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import { Card, CardBody, Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { CloseEditMessageIcon } from '../../../theme/icons';
import S3Image from '../../S3Image';

export default function ProductCard({ product, productDetail, removeProduct }) {
  const { locale } = webCore.contexts.useTranslation();
  const theme = useTheme();
  const starRatedColor = theme.colors.orange[500];
  return (
    <LazyLoad>
      <Card position="relative" boxShadow="none">
        <CardBody
          p={0}
          borderColor="grey.300"
          borderWidth={1}
          borderRadius="10px"
        >
          <Box w="100%" position="relative">
            <Box
              pos="absolute"
              right="7px"
              top="7px"
              cursor="pointer"
              onClick={() => removeProduct(productDetail?.id)}
            >
              <CloseEditMessageIcon stroke="white" w={4} h={4} />
            </Box>
            <S3Image
              folder={S3Image.folders.FOLDER_PRODUCTS}
              imageKey={productDetail?.images?.[0]?.imageKey || ''}
              size={'201x172'}
              alt={productDetail?.title?.[locale] || product?.title?.[locale]}
              borderRadius="10px 10px 0 0"
              objectFit="cover"
              w="100%"
              h={24}
            />
          </Box>
          <Box pt={4} px={2}>
            <Box h={4}>
              <Text variant={{ base: 'body-reg-lg', md: 'sub-head-reg' }}>
                {utils.helpers.string.truncate(
                  productDetail?.title?.[locale] || product?.title?.[locale],
                  10
                )}
              </Text>
            </Box>
            <Flex>
              <Text
                variant={{
                  base: 'body-reg-sm-bold',
                  sm: 'sub-head-bold',
                  md: 'h3-bold',
                }}
              >
                {utils.helpers.currency.beautify(
                  productDetail?.discountedPrice || productDetail?.price
                )}
                {/* @TODO: show original price with line-through if product was discounted */}
              </Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex>
                <StarRatings
                  rating={product?.reviewAverage}
                  numberOfStars={5}
                  starRatedColor={starRatedColor}
                  starWidthAndHeight="13px"
                  starDimension="16px"
                  starSpacing="2px"
                  starEmptyColor="white"
                  className="star-ratings"
                />
                <Text variant="body-reg-sm" mt={1} ml={1}>
                  ({product?.reviewCount})
                </Text>
              </Flex>
            </Flex>
          </Box>
        </CardBody>
      </Card>
    </LazyLoad>
  );
}
