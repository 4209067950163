import React, { useMemo } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function EmptyStateChannel({ chatRoom }) {
  const { t } = webCore.contexts.useTranslation();

  const roomName = useMemo(() => {
    const [firstSection, secondSection] = chatRoom.roomName.split(' ');
    return {
      firstSection: firstSection || '',
      secondSection: secondSection || '',
    };
  }, [chatRoom]);

  return (
    <Flex alignItems="center" flexDirection="column" mt={11}>
      <Flex pos="relative" w="72px" h="72px">
        <webApp.components.Avatar
          image={
            chatRoom?.avatarUrl
              ? chatRoom?.avatarUrl
              : s3Storage.helpers.read.buildUrlChatRoomAvatar(
                  chatRoom?.avatarKey
                )
          }
          firstName={roomName.firstSection}
          lastName={roomName.secondSection}
          width="72px"
          height="72px"
          borderRadius={36}
          color="white.50"
          background={chatRoom?.color ? chatRoom.color : 'green.500'}
        />
      </Flex>
      <Text mt={3} variant="sub-head-semi" textAlign="center">
        {chatRoom.roomName}
      </Text>
      <Text
        variant="sub-head-reg"
        color="grey.600"
        textAlign="center"
        px={20}
        mt={2}
      >
        {t(
          'Ready to chat? Hit the button and let`s get the conversation rolling! 💬'
        )}
      </Text>
    </Flex>
  );
}
