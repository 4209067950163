import React, { useContext, createContext, useEffect, useRef } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import utils from '@happylife-a/utils';
import webCore from '@happylife-a/web-core';
import * as Sentry from '@sentry/react';

export const SentryContext = createContext({
  Sentry: Sentry,
  canUseSentry: false,
});

export const useSentry = () => useContext(SentryContext);

function buildReleaseVersion() {
  const params = {};
  if (webCore.versioning.getPackage()) {
    params.release = webCore.versioning.getPackage();
  }
  if (webCore.versioning.getDate() && webCore.versioning.getGitShort()) {
    params.dist = `${webCore.versioning.getDate()}~~${webCore.versioning.getGitShort()}`;
  }

  return params;
}

export function SentryProvider({ environment, dsn, children }) {
  const refInitialized = useRef(false);

  const isProduction = webCore.environment.isEnvProduction();
  const isLocalMode = webCore.environment.isModeLocal();
  const isLocalhost = ['localhost', '127.0.0.1'].includes(
    window.location.hostname
  );

  const canUseSentry = isProduction && !isLocalMode && !isLocalhost && !!dsn;

  useEffect(() => {
    if (refInitialized.current || !canUseSentry) {
      return;
    }

    refInitialized.current = true;

    const sentryEnv = `${environment}:${webCore.environment.getMode()}`;
    utils.helpers.logging.info('[Sentry] Initializing sentry:', {
      dsn: dsn,
      environment: sentryEnv,
    });

    Sentry.init({
      ...buildReleaseVersion(),
      environment: sentryEnv,
      dsn: dsn,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: useEffect,
          useLocation: useLocation,
          useNavigationType: useNavigationType,
          createRoutesFromChildren: createRoutesFromChildren,
          matchRoutes: matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          networkDetailAllowUrls: [/api\/.+/],
          networkRequestHeaders: ['X-Client-Information'],
          networkResponseHeaders: [
            'X-API-Name',
            'X-API-Version',
            'X-Request-Start',
            'X-Request-End',
            'X-Request-Duration',
            'X-Request-ID',
            'X-Response-From',
            'X-Response-Cache-Start',
            'X-Response-Cache-End',
            'X-Response-Cache-Age-Duration',
            'X-Response-From',
            'X-Image-MimeType',
          ],
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    });
  }, [canUseSentry]);

  return (
    <SentryContext.Provider
      value={{
        canUseSentry: canUseSentry,
        Sentry: Sentry,
      }}
    >
      {children}
    </SentryContext.Provider>
  );
}
