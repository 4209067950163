import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { Box, useOutsideClick } from '@chakra-ui/react';
import { theme } from '../../theme';
import ScrollableBox from '../ScrollableBox';

export default function Predictions({
  showPredictions,
  keyExtractor = 'id',
  mouseHovered,
  predictions,
  onItemSelect,
  setMouseHovered,
  onOutsideClick,
  coordinates,
  renderItem,
}) {
  const refPredictionsWrapper = useRef(null);

  useOutsideClick({
    ref: refPredictionsWrapper,
    handler: onOutsideClick,
  });

  if (!showPredictions || predictions?.length < 1) {
    return null;
  }

  return createPortal(
    <ScrollableBox
      scrollbarThumbColor={theme.colors.grey[500]}
      ref={refPredictionsWrapper}
      bg="white"
      position="absolute"
      left={`${coordinates.x}px`}
      top={`${coordinates.y + coordinates.h + 2}px`}
      borderRadius="md"
      boxShadow="2px 2px 8px rgba(0, 0, 0, 0.1)"
      onMouseOver={() => setMouseHovered(true)}
      overflowX="hidden"
      maxH="480px"
      w={`${coordinates.w - 5}px`}
      zIndex={999999}
    >
      {predictions.map((prediction, index) => (
        <>
          {typeof renderItem === 'function' ? (
            renderItem({
              item: prediction,
              index: index,
              onItemSelect: onItemSelect,
            })
          ) : (
            <Box
              key={prediction[keyExtractor]}
              p={2}
              _hover={{
                bg: 'blue.100',
                cursor: 'pointer',
              }}
              bg={index === 0 && !mouseHovered && 'blue.100'}
              onClick={() => onItemSelect(prediction)}
            >
              {prediction.label}
            </Box>
          )}
        </>
      ))}
    </ScrollableBox>,
    document.body
  );
}
