import React, { useState, useCallback, useEffect } from 'react';
import firebase from '@happylife-a/firebase';
import webCore from '@happylife-a/web-core';
import {
  Flex,
  InputGroup,
  Input,
  Box,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { useScreenSize } from '../../contexts';
import { theme } from '../../theme';
import {
  ChatEditSendMessageIcon,
  ChatSendFilledIcon,
  ChatSendIcon,
  ChatSmileIcon,
  ChatThreadIcon,
} from '../../theme/icons';
import { ReplyInfo } from '../Message';
import AttachableMenu from './AttachableMenu';
import EditMessageSection from './EditMessageSection';
import SelectedImages from './SelectedImages';
import SelectedProducts from './SelectedProducts';

function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

const hasValidMedia = (obj, checkMediaKey = false) => {
  const mediaValid = obj && obj.media && !isEmptyObject(obj.media);
  const mediaKeyValid = checkMediaKey ? obj.mediaKey !== null : true;
  return mediaValid && mediaKeyValid;
};

export default function ChatInput({
  messagingType,
  firebaseRoomId,
  firebaseThreadId,
  threadMessageId,
  reply,
  editMessage,
  setShouldUpdateScrollTop,
  userName,
  closeReply,
  setEditMessage,
  onSend,
  chatRoom,
  onCreateThreadMessage,
  onOpenProductsModal,
  products,
  setProducts,
  setIsImageSelected,
}) {
  const [message, setMessage] = useState('');
  const [selectedImages, setSelectedImages] = useState({});
  const [isUploadingImages, setIsUploadingImages] = useState(false);

  const screenSize = useScreenSize();

  const inputRef = React.useRef(null);
  const { t } = webCore.contexts.useTranslation();
  const { user } = webCore.contexts.useUser();

  const sendMessageOptions = {
    firebaseRoomId: firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    threadMessageId: threadMessageId,
  };

  const updateMessageOptions = {
    firebaseRoomId: firebaseRoomId,
    firebaseThreadId: firebaseThreadId,
    messageId: editMessage?.id,
  };

  const chatLib = firebase.libraries.messaging[messagingType];
  const hookLib = webCore.coreHooks.messaging[messagingType];

  const sendMessage = hookLib.useSendMessage(sendMessageOptions);
  const updateMessage = hookLib.useUpdateMessage(updateMessageOptions);

  const handleChange = (e) => {
    setMessage(e.target.value);
    adjustInputHeight();
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = '40px';
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        100
      )}px`;
    }
  };

  const onSendPress = useCallback(
    (event) => {
      event.preventDefault();
      setShouldUpdateScrollTop(true);
      const cleanMessage = message.trim();

      if (
        cleanMessage !== '' ||
        Object.values(selectedImages).some((obj) => hasValidMedia(obj, true)) ||
        products?.length > 0
      ) {
        let mediaKeys = [];
        let productDetailIds = [];

        if (
          selectedImages &&
          Object.values(selectedImages).some((obj) => hasValidMedia(obj, true))
        ) {
          mediaKeys = Object.values(selectedImages).map(
            ({ mediaKey }) => mediaKey
          );
        }

        if (products?.length > 0) {
          productDetailIds = products.map((product) => ({
            productDetailId: product?.id,
            productId: product?.product.id,
          }));
        }

        if (
          chatRoom?.type === chatLib.constants.CHAT_ROOM_TYPE_CHANNEL &&
          typeof onCreateThreadMessage === 'function'
        ) {
          onCreateThreadMessage(cleanMessage, mediaKeys);
        } else {
          const messageInfo = reply
            ? chatLib.message.builder.reply({
                editMessage: editMessage,
                content: cleanMessage,
                chosenMedias: mediaKeys,
                replyMessage: reply,
                sender: {
                  id: user.id,
                  avatarKey: user.avatarKey,
                  name: user.nickname,
                },
              })
            : products?.length > 0
              ? chatLib.message.builder.product({
                  content: cleanMessage,
                  sender: {
                    id: user.id,
                    avatarKey: user.avatarKey,
                    name: user.nickname,
                  },
                  products: productDetailIds,
                })
              : chatLib.message.builder.simple({
                  editMessage: editMessage,
                  content: cleanMessage,
                  chosenMedias: mediaKeys,
                  sender: {
                    id: user.id,
                    avatarKey: user.avatarKey,
                    name: user.nickname,
                  },
                });

          if (editMessage) {
            updateMessage(messageInfo);
          } else {
            sendMessage(messageInfo);
          }

          if (typeof onSend === 'function') {
            onSend(messageInfo, cleanMessage);
          }
        }
      }

      if (inputRef.current) {
        inputRef.current.style.height = '40px';
      }

      setSelectedImages({});
      setMessage('');
      setIsUploadingImages(false);

      if (typeof setEditMessage === 'function') {
        setEditMessage(null);
      }
      if (typeof closeReply === 'function') {
        closeReply();
      }
      if (typeof setProducts === 'function') {
        setProducts([]);
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [message, selectedImages, reply, firebaseThreadId, chatRoom, products]
  );

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      if (message.trim() !== '') {
        onSendPress(event);
      }
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (editMessage?.message?.content) {
      setMessage(editMessage.message.content);
      closeReply();
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 0);
    }
  }, [editMessage]);

  useEffect(() => {
    if (reply) {
      setEditMessage(null);
      setMessage('');
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 0);
    }
  }, [reply]);

  useEffect(() => {
    setMessage('');
    setSelectedImages({});
    setIsUploadingImages(false);
  }, [firebaseRoomId, firebaseThreadId]);

  useEffect(() => {
    if (typeof setIsImageSelected === 'function') {
      setIsImageSelected(Object.keys(selectedImages).length > 0);
    }
  }, [setIsImageSelected, selectedImages]);

  return (
    <Flex flexDir="column">
      {Object.values(selectedImages).some((obj) =>
        hasValidMedia(obj, false)
      ) && (
        <SelectedImages
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          setIsUploadingImages={setIsUploadingImages}
        />
      )}
      {products?.length > 0 && (
        <SelectedProducts products={products} setProducts={setProducts} />
      )}
      {reply && (
        <ReplyInfo reply={reply} userName={userName} closeReply={closeReply} />
      )}
      {editMessage && (
        <EditMessageSection
          setEditMessage={setEditMessage}
          setMessage={setMessage}
        />
      )}
      <Flex
        px={screenSize.isMinTablet ? 6 : 2}
        py={4}
        gap={5}
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <AttachableMenu
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            onOpenProductsModal={onOpenProductsModal}
            setIsUploadingImages={setIsUploadingImages}
          />
        </Box>
        {!chatRoom?.type ===
          firebase.libraries.messaging.chatting.constants
            .CHAT_ROOM_TYPE_CHANNEL && (
          <Box>
            <ChatThreadIcon color={theme.colors.primary[500]} w={6} h={6} />
          </Box>
        )}
        <Flex flex={1} pos="relative">
          <InputGroup>
            <Input
              ref={inputRef}
              as="textarea"
              resize="none"
              value={message}
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              placeholder={`${t('Write a message')}...`}
              bgColor="grey.50"
              borderRadius="8px"
              border="none"
              fontSize="16px"
              fontWeight={400}
              px={4}
              py={2}
              css={{
                '&::-webkit-scrollbar': {
                  width: '0px',
                },
              }}
            />
            <InputRightElement>
              <ChatSmileIcon w={6} h={6} />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {message !== '' ||
        Object.values(selectedImages).some((obj) => hasValidMedia(obj, true)) ||
        products?.length > 0 ? (
          <Box cursor="pointer" onClick={onSendPress}>
            {editMessage ? (
              <ChatEditSendMessageIcon
                color={theme.colors.primary[500]}
                w={6}
                h={6}
              />
            ) : (
              <ChatSendFilledIcon
                color={theme.colors.primary[500]}
                w={6}
                h={6}
              />
            )}
          </Box>
        ) : (
          <>
            {isUploadingImages ? (
              <Spinner color="primary.500" size="md" thickness="2px" />
            ) : (
              <Box cursor="pointer" onClick={() => inputRef.current.focus()}>
                <ChatSendIcon fill={theme.colors.grey[900]} w={6} h={6} />
              </Box>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
