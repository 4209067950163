import React, { useContext, createContext } from 'react';
import webApp from '@happylife-a/web-app';

const HEADER_CATEGORIES_HEIGHT = 41;
const HEADER_MENU_HEIGHT = 72;

const HeaderScrollableContext = createContext({
  menuHeight: HEADER_MENU_HEIGHT,
  categoriesHeight: HEADER_CATEGORIES_HEIGHT,
  totalHeight: 0,
  scrollDirection: 'up',
});

export function useHeaderScrollable() {
  return useContext(HeaderScrollableContext);
}

export function HeaderScrollableProvider({ children }) {
  const totalHeight = HEADER_MENU_HEIGHT + HEADER_CATEGORIES_HEIGHT;
  const scrollDirection = webApp.hooks.useScrollDirection();

  return (
    <HeaderScrollableContext.Provider
      value={{
        menuHeight: HEADER_MENU_HEIGHT,
        categoriesHeight: HEADER_CATEGORIES_HEIGHT,
        totalHeight: totalHeight,
        scrollDirection: scrollDirection || 'up',
      }}
    >
      {children}
    </HeaderScrollableContext.Provider>
  );
}
