import { buildQueryVariables } from '../../helpers';

/**
 * @param {{
 *   CustomerGrantService: ReturnType<import('../../services/CustomerGrantService').default>,
 * }} param0
 */
export default function customerGrantUseCase({ CustomerGrantService }) {
  const getCustomerGrantStatus = CustomerGrantService.getCustomerGrantStatus;

  const getCustomerGrants = (variables) => {
    const serviceVariables = buildQueryVariables(variables);
    return CustomerGrantService.getCustomerGrants(serviceVariables);
  };

  const requestCustomerGrant = (input) =>
    CustomerGrantService.requestCustomerGrant(input);

  const updateCustomerGrantStatus = (input) =>
    CustomerGrantService.updateCustomerGrantStatus(input);

  return {
    getCustomerGrantStatus: getCustomerGrantStatus,
    updateCustomerGrantStatus: updateCustomerGrantStatus,
    getCustomerGrants: getCustomerGrants,
    requestCustomerGrant: requestCustomerGrant,
  };
}
