import React, { useState } from 'react';
import webCore from '@happylife-a/web-core';
import { Button, Flex, Text } from '@chakra-ui/react';

function getValues(status) {
  return typeof status === 'string' ? status : status.values;
}
function getText(status) {
  return typeof status === 'string' ? status : status.text;
}

function checkIsActive(status, activeStatus) {
  if (status.text) {
    return status.text === activeStatus;
  }
  return status === activeStatus;
}

/**
 * @param {{
 *  statuses: Array<string | { text: string; values: Array<string> }>;
 * statusValue?: string;
 * onStatusChange: (newStatus: string) => void;
 * }} param0
 */
export default function StatusButtons({
  statuses,
  statusValue,
  onStatusChange,
}) {
  const { t } = webCore.contexts.useTranslation();
  const [activeStatus, setActiveStatus] = useState(() =>
    getText(statusValue ? statusValue : statuses[0])
  );

  const onClick = (status) => {
    setActiveStatus(getText(status));
    if (typeof onStatusChange === 'function') {
      onStatusChange(getValues(status));
    }
  };

  return (
    <Flex mt={5} gap={2}>
      {statuses.map((status, idx) => (
        <Button
          key={`status-btn-${status}-${idx}`}
          borderWidth={1}
          borderRadius="4px"
          borderColor={
            checkIsActive(status, activeStatus) ? 'secondary.500' : 'grey.300'
          }
          bg="transparent"
          onClick={() => onClick(status)}
        >
          <Text as="span" variant="sub-head-med">
            {t(getText(status))}
          </Text>
        </Button>
      ))}
    </Flex>
  );
}
