import React, { useMemo } from 'react';
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';

const generateRandomMessage = () => ({
  isSender: Math.random() < 0.7,
  width: Math.floor(Math.random() * (350 - 100 + 1)) + 100,
  height: Math.floor(Math.random() * (80 - 50 + 1)) + 50,
});

const generateMessageData = (count) => {
  return Array.from({ length: count }, generateRandomMessage);
};

export default function MessageSkeleton() {
  const messageData = useMemo(() => generateMessageData(7), []);

  return (
    <Flex flex={1} flexDirection="column" gap={4}>
      {messageData.map((message, idx) => (
        <Flex
          key={`message-${message.isSender}-${idx}`}
          alignSelf={message.isSender ? 'flex-end' : 'flex-start'}
          flexDirection="row"
          alignItems="center"
          gap={1}
        >
          {!message.isSender && (
            <SkeletonCircle
              size={10}
              startColor="grey.300"
              endColor="grey.100"
            />
          )}
          <Skeleton
            startColor="grey.300"
            endColor="grey.100"
            height={message.height + 'px'}
            width={message?.width}
            borderRadius={12}
            {...(message.isSender
              ? { borderBottomRightRadius: 0 }
              : { borderBottomLeftRadius: 0 })}
          />
        </Flex>
      ))}
    </Flex>
  );
}
