import React, { useMemo } from 'react';
import s3Storage from '@happylife-a/s3-storage';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Text } from '@chakra-ui/react';

export default function EmptyState({ chatRoom }) {
  const { t } = webCore.contexts.useTranslation();

  const checkRoomType = webCore.hooks.chat.useCheckRoomType();
  const getDirectFriend = webCore.hooks.chat.useGetDirectFriend();
  const getRoomTwoUsers = webCore.hooks.chat.useGetRoomTwoUsers();

  const user = useMemo(() => {
    if (checkRoomType(chatRoom).isDirect) {
      return getDirectFriend(chatRoom);
    }

    return getRoomTwoUsers(chatRoom);
  }, []);

  return (
    <Flex alignItems="center" flexDirection="column" mt={11}>
      <webApp.components.Avatar
        image={
          user?.avatarUrl
            ? user?.avatarUrl
            : s3Storage.helpers.read.buildUrlChatRoomAvatar(user?.avatarKey)
        }
        firstName={user?.nickname || ''}
        lastName={''}
        width="72px"
        height="72px"
        borderRadius={36}
        color="white.50"
        background="green.50"
      />
      <Text mt={3} variant="sub-head-semi" textAlign="center">
        {user?.nickname}
      </Text>
      <Text
        variant="sub-head-reg"
        color="grey.600"
        textAlign="center"
        px={20}
        mt={2}
      >
        {t(
          'Ready to chat? Hit the button and let`s get the conversation rolling! 💬'
        )}
      </Text>
    </Flex>
  );
}
