export const ENUM_MODAL_SIGN_IN = 'sign-in';
export const ENUM_MODAL_SIGN_UP = 'sign-up';
export const ENUM_MODAL_CONFIRM_SIGN_UP = 'confirm-sign-up';
export const ENUM_MODAL_SELLER_CONFIRM_SIGN_UP = 'confirm-sign-up-seller';
export const ENUM_MODAL_FORGOT_PASSWORD = 'forgot-password';
export const ENUM_MODAL_RESET_PASSWORD = 'reset-password';
export const ENUM_MODAL_CREATE_PRODUCT_CATEGORY = 'create-product-category';
export const ENUM_MODAL_QUICK_VIEW = 'product-quick-view';
export const ENUM_MODAL_LOG_OUT = 'log-out';
export const ENUM_MODAL_EDIT_PROFILE = 'edit-profile';
export const ENUM_MODAL_EDIT_SELLER_PRODUCTS_LIST = 'edit-seller-products-list';
export const ENUM_MODAL_ADD_EDIT_DELIVERY_ADDRESS = 'add-edit-delivery-address';
export const ENUM_MODAL_MANAGE_DELIVERY_ADDRESSES = 'manage-delivery-addresses';
export const ENUM_MODAL_REVIEW_PRODUCT = 'review-product';
export const ENUM_MODAL_CREATE_EDIT_DELIVERY_PARTNER =
  'create-edit-delivery-partner';
export const ENUM_MODAL_DELETE_DELIVERY = 'delete-delivery';
export const ENUM_MODAL_DELETE_SELLER = 'delete-seller';
export const ENUM_MODAL_ADD_EDIT_PICKUP_ADDRESS = 'add-edit-pickup-address';
export const ENUM_MODAL_MANAGE_PICKUP_ADDRESSES = 'manage-pickup-addresses';
export const ENUM_MODAL_DELETE_PAYMENT_METHOD = 'delete-payment-method';
export const ENUM_MODAL_ORDER_DETAILS = 'order-details';
export const ENUM_MODAL_PENDING_ORDER_APPROVE = 'pending-order-approve';
export const ENUM_MODAL_PENDING_ORDER_DENY = 'pending-order-deny';

export const ENUM_MODAL_PRODUCT_PHOTOS = 'product-photos';
export const ENUM_MODAL_DENY_PRODUCT = 'product-deny';
export const ENUM_MODAL_SELLER_VERIFICATION = 'seller-verification';
export const ENUM_MODAL_CHOOSE_PAYMENT_METHODS = 'choose-payment-methods';
export const ENUM_MODAL_SELLER_DENY = 'seller-deny';
export const ENUM_MODAL_FORMFIELD_SEARCH_RESULTS = 'formfield-search-results';
export const ENUM_MODAL_DELETE_ACCOUNT = 'delete-account-modal';

export const ENUM_STATUS_ALL = 'all';
export const ENUM_STATUS_DENIED = 'denied';
export const ENUM_STATUS_PENDING = 'pending';
export const ENUM_STATUS_IN_REVIEW = 'in_review';
export const ENUM_STATUS_DECLINED = 'declined';
export const ENUM_STATUS_REJECTED = 'rejected';
export const ENUM_STATUS_NEW = 'new';
export const ENUM_STATUS_CANCELED = 'canceled';
export const ENUM_STATUS_DELIVERED = 'delivered';
export const ENUM_STATUS_IN_PROCESS = 'in_process';

export const ENUM_CREDIT_DEBIT_PAYMENT = 'credit-debit';
export const ENUM_MODAL_SELLER_CHOOSE_PRODUCT_IMAGE =
  'seller-choose-product-image';

export const ENUM_MODAL_PROFILE_VIEW_DELIVERY_ADDRESS =
  'profile-view-delivery-address';
export const ENUM_MODAL_PROFILE_VIEW_PICKUP_ADDRESS =
  'profile-view-pickup-address';

export const ENUM_MODAL_ANSWER_REVIEW = 'seller-product-answer-review';
export const ENUM_MODAL_DELIVERY_PROCESS = 'seller-product-delivery-process';

export const ENUM_MODAL_ADD_NEW_COLLECTIONS = 'add-new-collections';
export const ENUM_MODAL_USERS_SHARED = 'users-shared';
export const ENUM_MODAL_PRODUCT_ADD_COLLECTION = 'product_add_collection';

export const ENUM_MODAL_PRODUCT_DESCRIPTION = 'product-description';
export const ENUM_MODAL_PRODUCT_DENIED_ORDER = 'product-denied-order';
export const ENUM_MODAL_PRODUCT_ORDER_DETAILS = 'product-order-details';

export const ENUM_MODAL_CUSTOMER_TRACK_ORDER = 'customer-track-order';

export const ENUM_MODAL_SELLER_DEFINE_FORM_FIELD_VALUE =
  'seller-define-form-field-value';

export const ENUM_ADDRESS_TYPE_DELIVERY = 'delivery_address';
export const ENUM_ADDRESS_TYPE_PICK_UP = 'pick_up_address';

export const ENUM_MODAL_SELLER_CHOOSE_PRODUCT_VARIATIONAL_TITLE =
  'seller_choose_variational_title';

export const ENUM_MODAL_SELLER_DELETE_PRODUCTS = 'seller-delete-products';
export const ENUM_MODAL_SELLER_PRODUCT_DETAILED_QUANTITY =
  'seller-product-detailed-quantity';
export const ENUM_MODAL_SELLER_PRODUCT_DETAILED_PRICE =
  'seller-product-detailed-price';
export const ENUM_MODAL_SELLER_PRODUCT_DETAILED_DISCOUNTED_PRICE =
  'seller-product-detailed-discounted-price';

export const ENUM_MODAL_SELLER_FULL_INFO = 'seller-full-info';
export const ENUM_MODAL_PRODUCT_COLOR = 'product-color';
export const ENUM_MODAL_ADMIN_CUSTOMER_PERSONAL_INFO =
  'admin-customer-personal-info';
export const ENUM_MODAL_ADMIN_CUSTOMER_COMPANY_INFO =
  'admin-customer-company-info';

export const ENUM_MODAL_ADMIN_CUSTOMER_ADD_NEW_USER =
  'admin-customer-add-new-user';
export const ENUM_MODAL_PRODUCT_VALUE_APPROVE = 'product-color';

export const ENUM_MODAL_ADMIN_CUSTOMER_SEE_SHARED_ACCOUNTS =
  'admin-customer-see-shared-accounts';
export const ENUM_MODAL_ADMIN_CREATE_EDIT_PRICE_LIMIT =
  'admin-create-edit-price-limit';
export const ENUM_MODAL_ADMIN_VIEW_PRICE_LIMIT = 'admin-view-price-limit';
export const ENUM_MODAL_CREATE_CHAT = 'create-chat-modal';
export const ENUM_MODAL_MEMBERS = 'members-modal';
export const ENUM_MODAL_NEW_GROUP = 'create-new-group';
export const ENUM_MODAL_CREATE_CHANNEL = 'create-channel-modal';
export const ENUM_MODAL_MUTE_NOTIFICATIONS = 'mute-notifications-modal';
export const ENUM_MODAL_CHANNEL_DELETE = 'channel-delete-modal';

export const ENUM_MODAL_ADMIN_CHANGE_PASSWORD_MODAL =
  'admin-change-password-modal';

export const ENUM_MODAL_ADMIN_CHANGE_PASSWORD_SUCCESSFULLY_MODAL =
  'admin-change-password-successfully-modal';
export const ENUM_MODAL_FORWARD_MESSAGE = 'forward-message';
export const ENUM_MODAL_DELETE_MESSAGE = 'delete-message-modal';
export const ENUM_MODAL_PICKUP_ADDRESS_NAME_SURNAME =
  'pickup-address-name-surname';
export const ENUM_MODAL_DELIVERY_ADDRESS_DETAILS_FORM =
  'delivery-address-details-form';

export const ENUM_MODAL_SELLER_ADD_COLLABORATORS = 'seller-add-collaborators';

export const ENUM_MODAL_SUSPEND_COLLABORATORS = 'seller-suspend-collaborators';

export const ENUM_MODAL_ADMIN_DUPLICATE_CATEGORY = 'admin-duplicate-modal';

export const ENUM_MODAL_ADMIN_DUPLICATE_CATEGORY_ADD_TO_EXISTING =
  'admin-add-to-existing';

export const ENUM_MODAL_ADMIN_CREATE_EDIT_CATEGORY =
  'admin-create-edit-category';

export const ENUM_MODAL_ADMIN_PENDING_ORDERS_INFO = 'admin-pending-orders-info';

export const ENUM_MODAL_ADMIN_PENDING_ORDERS_DENIED =
  'admin-pending-orders-denied';

export const ENUM_MODAL_ADMIN_DELIVERY_PARTNERS_SINGLE_TRACK_ORDERS =
  'admin-delivery-partners-single-track-orders';

export const ENUM_MODAL_ADMIN_PRODUCT_MULTILANG = 'admin-product-multilang';

export const ENUM_MODAL_SELLER_FORGOT_PASSWORD = 'seller-forgot-password';

export const ENUM_MODAL_SELLER_RESET_PASSWORD = 'seller-reset-password';

export const ENUM_MODAL_GOOGLE_PLACE_AUTOCOMPLETE_MAP =
  'google_place_autocomplete_map_modal';

export const ENUM_MODAL_YANDEX_PLACE_AUTOCOMPLETE_MAP =
  'yandex_place_autocomplete_map_modal';

export const ENUM_MODAL_SELLER_CHOOSE_DROP_OF_POINT_ADDRESS = 'drop_of_point';
export const ENUM_MODAL_ADMIN_ADD_EDIT_SLIDE = 'admin-add-edit-slide';
export const ENUM_MODAL_ADMIN_ADD_EDIT_BADGE = 'admin-add-edit-badge';
export const ENUM_MODAL_DELETE_BADGE = 'admin-delete-badge';
export const ENUM_MODAL_SHARE_PRODUCT = 'customer-share-product';
export const ENUM_MODAL_SHARE_COLLECTION = 'share-collection';
export const ENUM_MODAL_ADMIN_ORDER_ITEM_DETAILS = 'admin-order-item-details';
export const ENUM_MODAL_ADMIN_BANNER_SLIDE_PREVIEW =
  'admin-banner-slide-preview';
export const ENUM_MODAL_LIST_TRACK_ORDERS = 'list-track-orders';
export const ENUM_MODAL_SELLER_ORDER_DETAILS = 'seller-order-details';
export const ENUM_MODAL_END_THREAD = 'end-thread';
export const ENUM_MODAL_PRODUCTS = 'products';
export const ENUM_MODAL_CS_SUBJECTS = 'cs-subjects';
export const ENUM_MODAL_CREATE_THREAD = 'create-thread';
export const ENUM_MODAL_APP_DOWNLOAD = 'app-download';

export const ENUM_MODAL_ADMIN_ADD_EDIT_PUSH_NOTIFICATION =
  'admin-add-edit-push-notification';

export const ENUM_MODAL_ADMIN_SELECT_GRANT_OPTION = 'admin-select-grant-option';
