import React from 'react';
import { Flex, Text, Icon, Menu } from '@chakra-ui/react';
import { LocalizedLink } from '../../components/LocalizedLink';

export default function NavItem({
  icon,
  title,
  active,
  navSize,
  isDashboard,
  to,
  isDisabled,
  textProps = {},
}) {
  return (
    <Flex flexDir="column" w="100%" alignItems={'center'} mt={1}>
      <Menu isLazy={true} placement="right">
        <Flex
          as={LocalizedLink}
          // eslint-disable-next-line no-script-url
          to={isDisabled ? '#' : to}
          backgroundColor={active && 'secondary.500'}
          color={active ? 'white' : isDisabled ? 'grey.800' : 'black'}
          p={3}
          borderRadius={8}
          _hover={{
            textDecor: 'none',
          }}
          w={navSize === 'large' && '100%'}
        >
          <Flex alignItems="center" h={5}>
            <Icon
              as={icon}
              fontSize="xl"
              h={5}
              w={5}
              fill={
                isDashboard && active
                  ? 'white'
                  : isDashboard && !active
                    ? 'black'
                    : 'none'
              }
              stroke={active ? 'white' : isDisabled ? 'grey.800' : 'black'}
              color={active ? 'secondary.500' : 'gray.800'}
            />

            <Text
              ml={4}
              variant="body-reg-lg"
              textAlign="left"
              display={navSize === 'small' ? 'none' : 'flex'}
              {...textProps}
            >
              {title}
            </Text>
          </Flex>
        </Flex>
      </Menu>
    </Flex>
  );
}
