import React from 'react';
import { Link } from 'react-router-dom';
import useLocalizedUrlBuilder, {
  REMOVE_DEFAULT_LOCALE,
} from '../../hooks/useLocalizedUrlBuilder';

function LocalizedLink({
  domain = null,
  to: link1,
  href: link2,
  removeDefaultLocale = REMOVE_DEFAULT_LOCALE,
  locale = null,
  disabled = false,
  ...props
}) {
  const buildNewUrl = useLocalizedUrlBuilder(removeDefaultLocale);
  const link = link1 || link2;

  return (
    <Link
      {...props}
      to={typeof link !== 'string' ? link : buildNewUrl(domain, link, locale)}
      style={disabled ? { pointerEvents: 'none' } : undefined}
    />
  );
}

export default LocalizedLink;
