export const reactionsList = [
  { name: 'heart', emoji: '❤️' },
  { name: 'smile', emoji: '😄' },
  { name: 'star', emoji: '⭐️' },
  { name: 'sun', emoji: '☀️' },
  { name: 'moon', emoji: '🌙' },
  { name: 'fire', emoji: '🔥' },
  { name: 'rainbow', emoji: '🌈' },
  { name: 'coffee', emoji: '☕️' },
  { name: 'cake', emoji: '🍰' },
  { name: 'rocket', emoji: '🚀' },
  { name: 'music note', emoji: '🎵' },
  { name: 'book', emoji: '📚' },
  { name: 'pencil', emoji: '✏️' },
  { name: 'camera', emoji: '📷' },
  { name: 'plane', emoji: '✈️' },
  { name: 'earth', emoji: '🌍' },
  { name: 'globe', emoji: '🌐' },
  { name: 'clock', emoji: '⏰' },
  { name: 'umbrella', emoji: '☔️' },
  { name: 'taco', emoji: '🌮' },
  { name: 'soccer ball', emoji: '⚽️' },
  { name: 'anchor', emoji: '⚓️' },
  { name: 'tree', emoji: '🌳' },
  { name: 'dog', emoji: '🐶' },
  { name: 'cat', emoji: '🐱' },
  { name: 'unicorn', emoji: '🦄' },
  { name: 'pizza', emoji: '🍕' },
  { name: 'diamond', emoji: '💎' },
  { name: 'computer', emoji: '💻' },
  // more reactions here ...
];

export const reactionsByName = reactionsList.reduce(
  (acc, item) => ({
    ...acc,
    [item.name]: item.emoji,
  }),
  {}
);
