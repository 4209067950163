import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import firebase from '@happylife-a/firebase';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex } from '@chakra-ui/react';
import { useChatVisibility } from '~contexts/ChatVisibilityContext';
import useHeightAdjustment from '~hooks/useHeightAdjustment';
import useWindowDimensions from '~hooks/useWindowDimensions';
import EmptyStateChannel from '../Channels/EmptyState';
import EmptyStateGroup from '../Groups/EmptyState';
import MessageSkeleton from '../MessageSkeleton';
import EmptyState from '../MessagesTab/EmptyState';
import ThreadSkeleton from '../ThreadSkeleton';

export default function MessageList({
  chatRoom,
  shouldUpdateScrollTop,
  setShouldUpdateScrollTop,
  onReply,
  setShowMessageParams,
  setEditMessage,
  editMessage,
  products,
  isImageSelected,
  reply,
}) {
  const checkRoomType = webCore.hooks.chat.useCheckRoomType();
  const roomType = checkRoomType(chatRoom);
  const messagingType = roomType.isCustomerSupport
    ? firebase.libraries.messaging.MESSAGING_TYPES.CUSTOMER_SUPPORT
    : firebase.libraries.messaging.MESSAGING_TYPES.CHATTING;

  const { setIsOpenMessageTab, setIsOpenChatTab } = useChatVisibility();

  const libCore = webCore.coreHooks.messaging[messagingType];
  const libFirebase = firebase.libraries.messaging[messagingType];

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageCardHeight, setMessageCardHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { dispatch } = webApp.contexts.useModal();
  const { height } = useWindowDimensions();
  const { user } = webCore.contexts.useUser();

  const onOpenForwardModal = (message) => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_FORWARD_MESSAGE,
      props: {
        message: message,
        chatRoom: chatRoom,
        setShowMessageParams: setShowMessageParams,
      },
    });
  };

  const contentRef = useRef(null);

  const updateMessage = libCore.useUpdateMessage({
    firebaseRoomId: chatRoom?.firebaseRoomId,
    messageId: selectedMessage?.id,
  });

  const onMessageHover = useCallback((e, message) => {
    setMessageCardHeight(e.currentTarget.offsetHeight);
    setSelectedMessage(message);
  }, []);

  const onMessageHoverLeave = useCallback(() => {
    setSelectedMessage(null);
  }, []);

  const { messageContext } = webCore.contexts.useMessaging();
  const messageContextParams = useMemo(
    () => ({
      firebaseRoomId: chatRoom?.firebaseRoomId,
      messagingType: messagingType,
    }),
    []
  );

  const { messages, loadNextPageMessages, isInitialLoading } =
    messageContext.getByConfigs(messageContextParams);

  useEffect(() => {
    messageContext.configure(messageContextParams);
  }, []);

  const handleScroll = (e) => {
    if (isInitialLoading || isLoading) {
      return;
    }

    const { scrollTop } = e.target;
    if (scrollTop === 0 && !isInitialLoading) {
      setIsLoading(true);
      loadNextPageMessages(() => {
        setIsLoading(false);
        setShouldUpdateScrollTop(false);
      });
    }
  };

  const onOpenDeleteModal = () => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_DELETE_MESSAGE,
      props: {
        firebaseRoomId: chatRoom?.firebaseRoomId,
        messageId: selectedMessage?.id,
      },
    });
  };

  const onAddReaction = useCallback(
    (newReactionName) => {
      const messageInfo = libFirebase.message.toggleReaction(
        selectedMessage,
        newReactionName,
        user.id
      );

      updateMessage(messageInfo);
    },
    [selectedMessage]
  );

  useEffect(() => {
    if (contentRef.current && !isInitialLoading) {
      if (shouldUpdateScrollTop) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      } else {
        contentRef.current.scrollTop = 150;
      }
    }
  }, [messages, isInitialLoading, shouldUpdateScrollTop]);

  const tabHeight = useHeightAdjustment(
    roomType,
    products,
    isImageSelected,
    reply
  );

  const screenSize = webApp.contexts.useScreenSize();

  return (
    <webApp.components.ScrollableBox
      as={Flex}
      ref={contentRef}
      onScroll={handleScroll}
      borderTopWidth={screenSize.isMinTablet ? 1 : 0}
      borderTopColor="grey.600"
      overflowY="scroll"
      px={2}
      flexDir="column"
      pt={4}
      gap={6}
      height={screenSize.isMinTablet ? height - 375 : height - tabHeight}
    >
      {isInitialLoading ? (
        <>
          {roomType?.isCustomerSupport || roomType?.isChannel ? (
            <ThreadSkeleton roomType={roomType} />
          ) : (
            <MessageSkeleton />
          )}
        </>
      ) : (
        <>
          {messages.length > 0 ? (
            <>
              {messages.map((item, idx) => (
                <webApp.components.Message
                  key={`message-${item.id}-${idx}`}
                  message={item}
                  chatRoom={chatRoom}
                  firebaseRoomId={chatRoom?.firebaseRoomId}
                  messageId={selectedMessage?.id}
                  onOver={onMessageHover}
                  onMouseLeave={onMessageHoverLeave}
                  onReply={onReply}
                  onOpenForwardModal={onOpenForwardModal}
                  setEditMessage={setEditMessage}
                  editMessage={editMessage}
                  onOpenDeleteModal={onOpenDeleteModal}
                  messageCardHeight={messageCardHeight}
                  onAddReaction={onAddReaction}
                  setShowMessageParams={setShowMessageParams}
                  messagingType={messagingType}
                  setIsOpenMessageTab={setIsOpenMessageTab}
                  setIsOpenChatTab={setIsOpenChatTab}
                />
              ))}
            </>
          ) : (
            <>
              {chatRoom?.type ===
              libFirebase.constants.CHAT_ROOM_TYPE_CHANNEL ? (
                <EmptyStateChannel chatRoom={chatRoom} />
              ) : chatRoom?.type ===
                libFirebase.constants.CHAT_ROOM_TYPE_GROUP ? (
                <EmptyStateGroup chatRoom={chatRoom} />
              ) : (
                <EmptyState chatRoom={chatRoom} />
              )}
            </>
          )}
        </>
      )}
    </webApp.components.ScrollableBox>
  );
}
