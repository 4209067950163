import http, { API_CORE } from '../http';

export default function CustomerGrantService() {
  const getCustomerGrantStatus = async () => {
    const response = await http(API_CORE).get('/customer-grants/status');
    return response.data;
  };

  const getCustomerGrants = async (params = {}) => {
    const response = await http(API_CORE).get('/customer-grants', {
      params: params,
    });

    return response.data;
  };

  const requestCustomerGrant = async (input) => {
    const response = await http(API_CORE).post(
      '/customer-grants/request',
      input
    );
    return response;
  };

  const updateCustomerGrantStatus = async (input) => {
    const response = await http(API_CORE).patch(
      `/customer-grants/${input.grantId}/status`,
      input
    );
    return response;
  };

  return {
    getCustomerGrantStatus: getCustomerGrantStatus,
    updateCustomerGrantStatus: updateCustomerGrantStatus,
    getCustomerGrants: getCustomerGrants,
    requestCustomerGrant: requestCustomerGrant,
  };
}
