import '@happylife-a/polyfill';

import { configure as nativeConfigure, getInstances } from './configure';
import * as messaging from './messaging';
import * as analytics from './analytics';

const libraries = {
  messaging: {
    MESSAGING_TYPES: {
      CUSTOMER_SUPPORT: 'customerSupport',
      CHATTING: 'chatting',
    },
    customerSupport: messaging.customerSupport,
    chatting: messaging.chatting,
  },
  analytics: analytics.mainDriver,
  // ...
};

function setAnalyticsDriver(driver) {
  libraries.analytics = driver;
}

function disableAnalytics() {
  setAnalyticsDriver(analytics.disabledDriver);
}

/**
 * @param {import('../types').ConfigurationInterface} params
 * @returns {import('../types').InstancesMapInterface}
 */
function configure(params) {
  return nativeConfigure(params, messaging.onConfigure);
}

export { configure, getInstances, libraries };

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  configure: configure,
  getInstances: getInstances,
  setAnalyticsDriver: setAnalyticsDriver,
  disableAnalytics: disableAnalytics,
  libraries: libraries,
};
