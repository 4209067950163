import moment from 'moment';

export function calculateAge(birthday) {
  const dateOfBirth = new Date(birthday);
  const diff = Date.now() - dateOfBirth.getTime();

  const dateDiff = new Date(diff);
  const yearDiff = dateDiff.getUTCFullYear();

  return Math.abs(yearDiff - 1970);
}

export function registrationDate(date, years) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function dateOfExpiry(date, years) {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export function getEighteenYearsAgo() {
  const currentDate = new Date();
  return new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );
}

export function timestampToLocale(timestamp) {
  if (timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
  }

  return '';
}

export function dateTimeFormat(date) {
  return moment(date).format('MMM D, YYYY HH:mm');
}
