import * as utils from '@happylife-a/utils';

const joinFileNamePartsVia = '-';

function zerofill(value, length = 2) {
  return value.toString().padStart(length, '0');
}

export function generateFilenameWithExtension(fileExtension) {
  const randomId = utils.helpers.random.randomId();
  const uuid = utils.helpers.random.uuid();

  const today = new Date();
  const dateFormatted = [
    zerofill(today.getUTCFullYear(), 4),
    zerofill(today.getUTCMonth() + 1, 2),
    zerofill(today.getUTCDate(), 2),
    zerofill(today.getUTCHours(), 2),
    zerofill(today.getUTCMinutes(), 2),
    zerofill(today.getUTCSeconds(), 2),
    zerofill(today.getUTCMilliseconds(), 3),
  ].join('');

  const filename = `${uuid}.${fileExtension.toLowerCase()}`;
  return [dateFormatted, randomId, filename].join(joinFileNamePartsVia);
}

export function getUploadTimeFromPath(filepath) {
  const filename = filepath.split('/').pop();
  let timeString = filename
    .split(joinFileNamePartsVia)
    .reduce(
      (acc, item) => {
        if (!acc.skip) {
          if (/^\d+$/.test(item)) {
            acc.list.push(item);
          } else {
            acc.skip = true;
          }
        }
        return acc;
      },
      { list: [], skip: false }
    )
    .list.join('');

  const getValue = (length) => {
    timeString = timeString.replace(/\-/g, '');
    const part = timeString.substring(0, length);
    timeString = timeString.substring(length);

    return Number(part);
  };

  if (/^\d+$/.test(timeString)) {
    if (timeString.length === 17) {
      const date = new Date();
      date.setUTCFullYear(getValue(4));
      date.setUTCMonth(getValue(2) - 1);
      date.setUTCDate(getValue(2));
      date.setUTCHours(getValue(2));
      date.setUTCMinutes(getValue(2));
      date.setUTCSeconds(getValue(2));
      date.setUTCMilliseconds(getValue(3));

      return date;
    }

    if (timeString.length === 8) {
      const date = new Date();
      date.setUTCFullYear(getValue(4));
      date.setUTCMonth(getValue(2) - 1);
      date.setUTCDate(getValue(2));
      date.setUTCHours(0);
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      date.setUTCMilliseconds(0);

      return date;
    }
  }

  return null;
}
