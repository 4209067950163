import firebase from '@happylife-a/firebase';
import s3Storage from '@happylife-a/s3-storage';
import utils from '@happylife-a/utils';
import * as helpers from '../../../helpers';

/**
 * @param {{
 *   MessagingFirebaseService: ReturnType<import('../../services/MessagingFirebaseService').default>,
 *   MessagingChatRoomService: ReturnType<import('../../services/MessagingChatRoomService').default>,
 *   MessagingThreadService: ReturnType<import('../../services/MessagingThreadService').default>,
 *   MessagingRealtimeService: ReturnType<import('../../services/MessagingRealtimeService').default>,
 *   UserService: ReturnType<import('../../services/UserService').default>,
 *   AwsS3Service: ReturnType<import('../../services/AwsS3Service').default>,
 * }} param0
 */
export default function messagingUseCase({
  MessagingFirebaseService,
  MessagingChatRoomService,
  MessagingThreadService,
  MessagingRealtimeService,
  UserService,
  AwsS3Service,
}) {
  // @TODO: use socket connection instance to send and receive data.
  // eslint-disable-next-line no-unused-vars
  let socketConnection = null;

  // #region - firebase
  const loadChatRooms = async ({ lastPageFirstItem, itemsPerPage = 20 }) => {
    return MessagingFirebaseService.loadChatRooms({
      itemsPerPage: itemsPerPage,
      lastPageFirstItem: lastPageFirstItem,
    });
  };
  const loadChatRoomById = async ({ firebaseRoomId }) => {
    return MessagingFirebaseService.loadChatRoomById({
      firebaseRoomId: firebaseRoomId,
    });
  };

  const loadChatRoomMembers = async ({
    firebaseRoomId,
    memberTypes = null,
  }) => {
    return MessagingFirebaseService.loadChatRoomMembers({
      firebaseRoomId: firebaseRoomId,
      memberTypes: memberTypes,
    });
  };

  const loadMessages = async ({
    firebaseRoomId,
    firebaseThreadId,
    lastPageFirstItem,
    itemsPerPage = 20,
  }) => {
    return MessagingFirebaseService.loadMessages({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      itemsPerPage: itemsPerPage,
      lastPageFirstItem: lastPageFirstItem,
    });
  };

  const getTotalMessagesCount = async ({
    firebaseRoomId,
    firebaseThreadId,
  }) => {
    return MessagingFirebaseService.getTotalMessagesCount({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  };

  const sendMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    message,
  }) => {
    MessagingFirebaseService.sendMessage({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      threadMessageId: threadMessageId,
      message: message,
    });

    return message;
  };

  const updateMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    message,
    messageId,
  }) => {
    return MessagingFirebaseService.updateMessage({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      messageId: messageId,
      message: message,
    });
  };

  const deleteMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    messageId,
  }) => {
    return MessagingFirebaseService.deleteMessage({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      threadMessageId: threadMessageId,
      messageId: messageId,
    });
  };

  const createThread = async ({
    firebaseRoomId,
    title,
    content,
    sender,
    chosenMedias,
  }) => {
    const response = await MessagingThreadService.createThread({
      firebaseRoomId: firebaseRoomId,
      params: {
        firebaseRoomId: firebaseRoomId,
        threadName: title,
        message: content,
      },
    });

    await MessagingChatRoomService.updateLastMessage({
      firebaseRoomId: firebaseRoomId,
      message: buildLastMessage({
        type: firebase.libraries.messaging.chatting.constants
          .MESSAGE_TYPE_THREAD,
        medias: [],
        sender: sender,
        message: {
          content: title,
        },
      }),
    });

    return MessagingFirebaseService.createThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: response.chatThread.firebaseThreadId,
      title: title,
      content: content,
      sender: sender,
      chosenMedias: chosenMedias,
    });
  };

  const getChatThreadById = ({ chatRoomId, chatThreadId }) =>
    MessagingThreadService.getChatThreadById({
      chatRoomId: chatRoomId,
      chatThreadId: chatThreadId,
    });

  const endChatThread = async ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
  }) => {
    await MessagingFirebaseService.endChatThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      threadMessageId: threadMessageId,
    });

    return MessagingThreadService.endChatThread({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
    });
  };

  const onChatThreadEnd = ({
    firebaseRoomId,
    firebaseThreadId,
    threadMessageId,
    callback,
  }) => {
    return MessagingFirebaseService.onChatThreadEnd({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      threadMessageId: threadMessageId,
      callback: callback,
    });
  };

  const onMessageAdded = ({ firebaseRoomId, firebaseThreadId, callback }) => {
    MessagingFirebaseService.onMessageAdded({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      callback: callback,
    });
  };

  const onMessageChanged = ({ firebaseRoomId, firebaseThreadId, callback }) => {
    MessagingFirebaseService.onMessageChanged({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      callback: callback,
    });
  };

  const onMessageRemoved = ({ firebaseRoomId, firebaseThreadId, callback }) => {
    MessagingFirebaseService.onMessageRemoved({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      callback: callback,
    });
  };

  const onChatRoomAdded = ({ callback }) => {
    MessagingFirebaseService.onChatRoomAdded({ callback: callback });
  };

  const onChatRoomChanged = ({ callback }) => {
    MessagingFirebaseService.onChatRoomChanged({ callback: callback });
  };

  const onChatRoomRemoved = ({ callback }) => {
    MessagingFirebaseService.onChatRoomRemoved({ callback: callback });
  };
  // #endregion

  // #region - chat room
  const uploadRoomAvatar = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_CHAT_ROOM_AVATAR;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const searchChatRooms = (search) =>
    MessagingChatRoomService.searchChatRooms(search);

  const getChatRooms = (params = {}) =>
    MessagingChatRoomService.getChatRooms(params);
  const getChatRoomById = (id) => MessagingChatRoomService.getChatRoomById(id);

  const getChatRoomsFromAll = (params = {}) =>
    MessagingChatRoomService.getChatRoomsFromAll(params);
  const getChatRoomByIdFromAll = (id) =>
    MessagingChatRoomService.getChatRoomByIdFromAll(id);

  const createChatRoom = async (params) => {
    params.customerIds = params.userIds || params.customerIds;
    const response = await MessagingChatRoomService.createChatRoom(params);

    const { CHAT_ROOM_TYPE_GROUP, CHAT_ROOM_TYPE_CHANNEL } =
      firebase.libraries.messaging.chatting.constants;

    // @TODO: update logic, provider customer object from components instead of fetching again
    const usersByIdResponse = await UserService.getUsersByIds({
      ids: [
        ...new Set([
          response.chatRoom.ownerUserId,
          ...params.userIds,
          ...params.customerIds,
        ]),
      ],
    });

    const participants = usersByIdResponse.users.map((user) => ({
      id: user.id,
      name:
        [user.firstName, user.lastName].filter((item) => !!item).join(' ') ||
        user.nickname,
      avatarKey: user.avatarKey || '',
    }));

    const roomParams = {
      admins: participants.filter((participant) =>
        utils.helpers.id.same(participant.id, response.chatRoom.ownerUserId)
      ),
      members: participants.filter(
        (participant) =>
          !utils.helpers.id.same(participant.id, response.chatRoom.ownerUserId)
      ),
    };

    if (
      params.type === CHAT_ROOM_TYPE_GROUP ||
      params.type === CHAT_ROOM_TYPE_CHANNEL
    ) {
      roomParams.name = params.roomName;
      roomParams.avatar = params.avatarKey;
    }
    if (params.type === CHAT_ROOM_TYPE_CHANNEL) {
      roomParams.color = params.color;
      roomParams.description = params.description;
      roomParams.isPublic = params.isPublic;
    }

    await MessagingFirebaseService.createChatRoom({
      firebaseRoomId: response.chatRoom.firebaseRoomId,
      type: params.type,
      ...roomParams,
    });

    return response;
  };

  const createOrGetChatRoom = async (params) => {
    params.customerIds = params.userIds || params.customerIds;
    const response = await MessagingChatRoomService.createOrGetChatRoom(params);

    await Promise.all(
      response.newChatRooms.map((newFirebaseRoomId) =>
        MessagingFirebaseService.createChatRoom({
          firebaseRoomId: newFirebaseRoomId,
          name: '',
          avatar: '',
          type: '',
        })
      )
    );

    return response;
  };

  const connectShopToChannel = ({ channelChatRoomId, sellerId }) =>
    MessagingChatRoomService.connectShopToChannel({
      chatRoomId: channelChatRoomId,
      sellerId: sellerId,
    });

  const deleteChatRoom = async ({ firebaseRoomId }) => {
    await MessagingFirebaseService.deleteChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
    await MessagingChatRoomService.deleteChatRoom({
      firebaseRoomId: firebaseRoomId,
    });
  };

  const buildLastMessage = (message) => {
    return {
      type: message.type,
      medias: message.medias?.length || 0,
      sender: {
        id: message.sender?.id,
        name: message.sender?.name,
      },
      content: message?.forward
        ? message?.forward?.content
        : message.message.content,
    };
  };

  const updateRoomLastMessage = ({ firebaseRoomId, message }) =>
    MessagingChatRoomService.updateLastMessage({
      firebaseRoomId: firebaseRoomId,
      message: message,
    });

  const updateThreadLastMessage = ({
    firebaseRoomId,
    firebaseThreadId,
    message,
  }) =>
    MessagingThreadService.updateLastMessage({
      firebaseRoomId: firebaseRoomId,
      firebaseThreadId: firebaseThreadId,
      message: message,
    });

  const renameChatRoom = (id, roomName) =>
    MessagingChatRoomService.renameChatRoom(id, roomName);

  const changeChatRoomAvatar = (id, avatarKey) =>
    MessagingChatRoomService.changeChatRoomAvatar(id, avatarKey);

  const archiveChatRoom = (id) => MessagingChatRoomService.archiveChatRoom(id);

  const unarchiveChatRoom = (id) =>
    MessagingChatRoomService.unarchiveChatRoom(id);

  const addUserToChatRoom = async ({ firebaseRoomId, memberId }) => {
    await MessagingFirebaseService.addMemberToChatRoom({
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });

    return MessagingChatRoomService.addUserToChatRoom({
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });
  };

  const removeUserFromChatRoom = async ({ firebaseRoomId, memberId }) => {
    await MessagingFirebaseService.removeMemberFromChatRoom({
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });

    return MessagingChatRoomService.removeUserFromChatRoom({
      firebaseRoomId: firebaseRoomId,
      memberId: memberId,
    });
  };
  // #endregion

  // #region - messaging
  const uploadMedia = async (file) => {
    const folder = s3Storage.configs.folders.FOLDER_CHAT_MEDIA;
    const { binary, extension } = await helpers.buildBinaryFile(file);

    return AwsS3Service.uploadPublic(binary, {
      folder: folder,
      extension: extension,
    });
  };

  const uploadBulkMedia = async (files) => {
    const folder = s3Storage.configs.folders.FOLDER_CHAT_MEDIA;

    return Promise.all(
      files.map(async (file) => {
        const { binary, extension } = await helpers.buildBinaryFile(file);

        return AwsS3Service.uploadPublic(binary, {
          folder: folder,
          extension: extension,
        });
      })
    );
  };
  //#endregion

  const connectToSocket = () => {
    MessagingFirebaseService.connectToSocket();
    socketConnection = MessagingRealtimeService.connectToSocket();
  };

  const getCustomerSupportRoomsFromAdmin = () =>
    MessagingChatRoomService.getCustomerSupportRoomsFromAdmin();

  const registerSocketEvents = (events) =>
    MessagingRealtimeService.registerSocketEvents(events);

  return {
    // #region - firebase
    loadChatRooms: loadChatRooms,
    loadChatRoomById: loadChatRoomById,
    loadChatRoomMembers: loadChatRoomMembers,
    loadMessages: loadMessages,
    getTotalMessagesCount: getTotalMessagesCount,
    sendMessage: sendMessage,
    updateMessage: updateMessage,
    deleteMessage: deleteMessage,
    createThread: createThread,
    getChatThreadById: getChatThreadById,
    endChatThread: endChatThread,
    onChatThreadEnd: onChatThreadEnd,
    onMessageAdded: onMessageAdded,
    onMessageChanged: onMessageChanged,
    onMessageRemoved: onMessageRemoved,
    onChatRoomAdded: onChatRoomAdded,
    onChatRoomChanged: onChatRoomChanged,
    onChatRoomRemoved: onChatRoomRemoved,
    // #endregion

    // #region - chat room
    uploadRoomAvatar: uploadRoomAvatar,
    searchChatRooms: searchChatRooms,
    getChatRooms: getChatRooms,
    getChatRoomById: getChatRoomById,
    getChatRoomsFromAll: getChatRoomsFromAll,
    getChatRoomByIdFromAll: getChatRoomByIdFromAll,
    createChatRoom: createChatRoom,
    createOrGetChatRoom: createOrGetChatRoom,
    deleteChatRoom: deleteChatRoom,
    updateRoomLastMessage: updateRoomLastMessage,
    renameChatRoom: renameChatRoom,
    changeChatRoomAvatar: changeChatRoomAvatar,
    archiveChatRoom: archiveChatRoom,
    unarchiveChatRoom: unarchiveChatRoom,
    addUserToChatRoom: addUserToChatRoom,
    removeUserFromChatRoom: removeUserFromChatRoom,
    connectShopToChannel: connectShopToChannel,
    // #endregion

    // #region - thread
    updateThreadLastMessage: updateThreadLastMessage,
    // #endregion

    // #region - messaging
    buildLastMessage: buildLastMessage,
    uploadMedia: uploadMedia,
    uploadBulkMedia: uploadBulkMedia,
    //#endregion

    // #region - socket and events
    connectToSocket: connectToSocket,
    getCustomerSupportRoomsFromAdmin: getCustomerSupportRoomsFromAdmin,
    registerSocketEvents: registerSocketEvents,
    //#endregion
  };
}
