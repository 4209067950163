import React, {
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
} from 'react';
import appBranding from '@happylife-a/branding';
import { Box } from '@chakra-ui/react';
import { theme } from '../../theme';
import useLoadYandexMaps from './useLoadYandexMaps';

export default function YandexMap({
  address,
  markers = [],
  height = '500px',
  enableClick = true,
  setSelectedAddress,
}) {
  const mapRef = useRef(null);
  const placemarkRef = useRef(null);
  const [isMapReady, setIsMapReady] = useState(false); // Track map readiness

  const initialCenter = useMemo(
    () => [
      appBranding.branding.countryParams.map.initialCenter.lat,
      appBranding.branding.countryParams.map.initialCenter.lng,
    ],
    []
  );

  const markerIcon = useMemo(
    () =>
      'data:image/svg+xml;charset=UTF-8,' +
      encodeURIComponent(
        `
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="${theme.colors.primary[500]}" />
            <circle cx="20.001" cy="20.0001" r="9.47368" fill="white" />
          </svg>
        `
      ),
    []
  );

  const createPlacemark = useCallback(
    (coordinates, balloonContent) => {
      return new window.ymaps.Placemark(
        coordinates,
        { balloonContent: balloonContent },
        {
          iconLayout: 'default#image',
          iconImageHref: markerIcon,
          iconImageSize: [30, 42],
          iconImageOffset: [-15, -42],
        }
      );
    },
    [markerIcon]
  );

  useLoadYandexMaps(() => {
    if (window.ymaps && !mapRef.current) {
      mapRef.current = new window.ymaps.Map('map', {
        center: initialCenter,
        zoom: 15,
        controls: ['zoomControl'],
      });

      setIsMapReady(true);

      if (enableClick) {
        mapRef.current.events.add('click', (e) => {
          const coords = e.get('coords');
          window.ymaps.geocode(coords).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);

            const clickedAddress = firstGeoObject.getAddressLine();
            const clickedCoordinates = firstGeoObject.geometry.getCoordinates();
            const city =
              firstGeoObject.getLocalities().length > 0
                ? firstGeoObject.getLocalities()[0]
                : '';

            const country = firstGeoObject.getCountry();
            const region =
              firstGeoObject.getAdministrativeAreas().length > 0
                ? firstGeoObject.getAdministrativeAreas()[0]
                : '';

            setSelectedAddress({
              address: clickedAddress,
              city: city,
              country: country,
              region: region,
              latitude: clickedCoordinates[0],
              longitude: clickedCoordinates[1],
              zipCode: '',
            });
          });
        });
      }
    }
  });

  useEffect(() => {
    if (isMapReady && address && mapRef.current) {
      const { latitude, longitude } = address;
      const newCoordinates = [latitude, longitude];

      if (placemarkRef.current) {
        mapRef.current.geoObjects.remove(placemarkRef.current);
      }

      placemarkRef.current = createPlacemark(newCoordinates, address?.address);
      mapRef.current.geoObjects.add(placemarkRef.current);
      mapRef.current.setCenter(newCoordinates, 17, {
        checkZoomRange: true,
        duration: 500,
        timingFunction: 'ease-in-out',
      });
    }
  }, [address, createPlacemark, isMapReady]);

  useEffect(() => {
    if (isMapReady && markers.length > 0 && mapRef.current) {
      mapRef.current.geoObjects.removeAll();
      const bounds = markers.map((marker) => {
        const { latitude, longitude } = marker.coordinates;
        const coordinates = [latitude, longitude];

        const placemark = createPlacemark(coordinates, marker.name);
        mapRef.current.geoObjects.add(placemark);

        return coordinates;
      });

      if (bounds.length > 0) {
        const mapBounds = window.ymaps.util.bounds.fromPoints(bounds);
        mapRef.current.setBounds(mapBounds, {
          checkZoomRange: true,
          duration: 500,
          timingFunction: 'ease-in-out',
        });

        mapRef.current.events.once('boundschange', () => {
          mapRef.current.setZoom(19);
        });
      }
    }
  }, [markers, createPlacemark, isMapReady]);

  return <Box id="map" style={{ width: '100%', height: height }} />;
}
