import React from 'react';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Box, Flex, Text } from '@chakra-ui/react';

export default function ThreadPageHeader({
  roomType,
  chatRoomParams,
  newState,
  onCloseThreadTab,
}) {
  const { t } = webCore.contexts.useTranslation();
  return (
    <>
      <Box h={74} />
      <Flex
        w="full"
        alignItems="center"
        pb={2}
        px={2}
        position="fixed"
        top={0}
        bgColor="white.50"
        zIndex={999}
      >
        <Flex
          h="full"
          justifyContent="center"
          alignItems="center"
          pr={2}
          onClick={onCloseThreadTab}
        >
          <webApp.theme.icons.ArrowDownLeft
            fill="none"
            stroke="black.50"
            w={6}
            h={6}
          />
        </Flex>
        <Flex justifyContent="center" alignItems="center" p="12px 0px 0px 0px">
          <Flex flex="1" textAlign="left" alignItems="center">
            <Box>
              <Text variant="sub-head-semi">
                {roomType?.isCustomerSupport
                  ? utils.helpers.string.truncate(t('Customer support'), 25)
                  : chatRoomParams?.chatRoom?.roomName}
              </Text>
              {roomType.isCustomerSupport || roomType?.isChannel ? (
                <Text variant="sub-head-reg">
                  {newState?.messagesCount} {t('replies')}
                </Text>
              ) : (
                <Text variant="sub-head-reg">{t('Offline')}</Text>
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
