import ReactGA from 'react-ga4';
import webCore from '@happylife-a/web-core';

function attribute(name) {
  if (typeof window === 'undefined') {
    return null;
  }

  const htmlElement = window?.document?.documentElement;
  return htmlElement?.getAttribute(name);
}

function configure(clientName) {
  const gaMeasurementId = attribute(
    'data-tracking-google-analytics-measurement-id'
  );
  if (gaMeasurementId) {
    ReactGA.initialize(gaMeasurementId);
  }

  webCore.versioning.setVersions(
    import.meta.env.VITE_BUILD_VERSION_DATE,
    import.meta.env.VITE_BUILD_VERSION_GIT_LONG,
    import.meta.env.VITE_BUILD_VERSION_GIT_SHORT,
    import.meta.env.VITE_BUILD_VERSION_PKG,
    import.meta.env.VITE_CURRENT_BUILD_VERSION
  );

  webCore.environment.setEnvAndMode(
    import.meta.env.VITE_NODE_ENV || attribute('data-build-env'),
    import.meta.env.VITE_NODE_ENV_MODE || attribute('data-build-mode')
  );

  if (typeof window === 'undefined') {
    webCore.httpClient.setClientAppName(clientName, 'SSR', 'SSR');
  } else {
    const version = attribute('data-build-version')?.replace(
      '{{CURRENT_BUILD_VERSION}}',
      'latest'
    );

    webCore.httpClient.setClientAppName(
      clientName,
      { ua: window.navigator.userAgent },
      version
    );
  }
}

export default configure;
