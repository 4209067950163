import React, { useEffect, useRef } from 'react';
import webApp from '@happylife-a/web-app';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@chakra-ui/react';

export default function SliderV4({
  images,
  mainBoxH,
  onSlideChange,
  activeSlideIndex,
}) {
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    onSlideChange(swiper.activeIndex);
  };

  useEffect(() => {
    if (
      swiperRef.current &&
      swiperRef.current.swiper &&
      typeof activeSlideIndex === 'number' &&
      !isNaN(activeSlideIndex)
    ) {
      swiperRef.current.swiper.slideTo(activeSlideIndex);
    }
  }, [activeSlideIndex]);

  return (
    <Box h="full" bgColor="grey.50" borderRadius="10px" overflow="hidden">
      <Swiper
        style={{ height: '100%' }}
        onSlideChange={handleSlideChange}
        ref={swiperRef}
      >
        {images?.map((image) => (
          <SwiperSlide
            key={`product-image-slide-${image}`}
            style={{ height: mainBoxH }}
          >
            <webApp.components.SlideItem image={image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
