import React, { useCallback, useMemo, useState } from 'react';
import firebase from '@happylife-a/firebase';
import utils from '@happylife-a/utils';
import webApp from '@happylife-a/web-app';
import webCore from '@happylife-a/web-core';
import { Flex, Spinner } from '@chakra-ui/react';
import MessageList from '~components/Chat/MessageList';
import CreateCsThreadButton from '~components/Chat/MessagesTab/CreateCsThreadButton';
import useChatRoom from '~components/Chat/MessagesTab/useChatRoom';
import Header from '~components/Chat/Mobile/MessagePage/Header';
import useDisableBodyScroll from '~hooks/useDisableBodyScroll';

export default function MessagePage() {
  const [shouldUpdateScrollTop, setShouldUpdateScrollTop] = useState(true);
  const [reply, setReply] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [products, setProducts] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(false);

  useDisableBodyScroll({
    screenWidthCondition: false,
    isDisabled: true,
  });

  const navigate = webApp.router.useNavigate();

  const params = webApp.router.useParams();

  const firebaseRoomId = params?.id;

  const { dispatch } = webApp.contexts.useModal();

  const checkRoomType = webCore.hooks.chat.useCheckRoomType();

  const { user } = webCore.contexts.useUser();

  const { data: response, isInitialLoading } =
    webCore.coreHooks.messaging.common.useGetChatRoomById({
      chatRoomId: firebaseRoomId,
    });

  const { mutate: createThread } =
    webCore.coreHooks.messaging.chatting.useCreateThread({
      firebaseRoomId: firebaseRoomId,
    });

  const chatRoomParams = useChatRoom({
    initialChatRoom: response?.chatRoom,
  });

  const ownerUserId = chatRoomParams?.chatRoom?.ownerUserId;
  const isOwner = utils.helpers.id.same(ownerUserId, user.id);

  const onReply = useCallback((swipedMessage) => {
    setReply(swipedMessage);
  }, []);

  const closeReply = useCallback(() => {
    setReply(null);
  }, []);

  const onCreateThreadMessage = (cleanMessage, mediaKeys) => {
    createThread({
      title: cleanMessage,
      content: cleanMessage,
      chosenMedias: mediaKeys,
      sender: {
        id: user.id,
        avatarKey: user.avatarKey,
        name: user.nickname,
      },
    });
  };

  const onOpenProductsModal = () => {
    dispatch({
      type: 'open',
      modal: webApp.constants.ENUM_MODAL_PRODUCTS,
      props: { setProducts: setProducts, firebaseRoomId: firebaseRoomId },
    });
  };

  const onCloseMessageTab = useCallback(() => {
    navigate('/chat');
  }, []);

  const roomType = checkRoomType(chatRoomParams?.chatRoom);

  const shouldShowChatInput = useMemo(() => {
    if (roomType.isDirect || roomType?.isGroup) {
      return true;
    }
    if (roomType.isChannel && isOwner) {
      return true;
    }
    return false;
  }, [roomType, isOwner]);

  return (
    <Flex w="100%" flex={1}>
      {isInitialLoading ? (
        <Flex w="full" flex={1} align="center" justify="center" pt={3}>
          <Spinner />
        </Flex>
      ) : (
        <Flex flexDirection="column" flex={1}>
          <Header
            roomType={roomType}
            chatRoomParams={chatRoomParams}
            onCloseMessageTab={onCloseMessageTab}
          />
          {chatRoomParams && (
            <>
              <MessageList
                key={chatRoomParams?.firebaseRoomId}
                chatRoom={chatRoomParams?.chatRoom}
                shouldUpdateScrollTop={shouldUpdateScrollTop}
                setShouldUpdateScrollTop={setShouldUpdateScrollTop}
                onReply={onReply}
                setEditMessage={setEditMessage}
                editMessage={editMessage}
                products={products}
                isImageSelected={isImageSelected}
                reply={reply}
              />
              {roomType?.isCustomerSupport ? (
                <CreateCsThreadButton />
              ) : (
                <>
                  {shouldShowChatInput && (
                    <webApp.components.ChatInput
                      messagingType={
                        firebase.libraries.messaging.MESSAGING_TYPES.CHATTING
                      }
                      firebaseRoomId={chatRoomParams?.firebaseRoomId}
                      setShouldUpdateScrollTop={setShouldUpdateScrollTop}
                      reply={reply}
                      userName={chatRoomParams?.chatRoom?.roomName}
                      closeReply={closeReply}
                      editMessage={editMessage}
                      setEditMessage={setEditMessage}
                      chatRoom={chatRoomParams?.chatRoom}
                      onCreateThreadMessage={onCreateThreadMessage}
                      onOpenProductsModal={onOpenProductsModal}
                      products={products}
                      setProducts={setProducts}
                      setIsImageSelected={setIsImageSelected}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}
