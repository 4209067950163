function getAllFonts() {
  const customFonts = [
    'Poppins',
    'Manrope',
    'Work Sans',
    'Roboto',
    'Raleway',
    'Lato',
  ];

  const systemFonts = [
    // apple
    '-apple-system',
    'BlinkMacSystemFont',
    'system-ui',
    'Apple Color Emoji',
    'Helvetica',
    'Helvetica Neue',

    // windows
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Segoe UI',

    // linux
    'Ubuntu',
    'Roboto',

    // common
    'Oxygen-Sans',
    'Arial',
    'Cantarell',
    'sans-serif',
  ];

  const allFonts = [...customFonts, ...systemFonts].map((font) =>
    font.includes(' ') ? `"${font}"` : font
  );

  return [...new Set(allFonts)];
}

export const styles = {
  global: {
    'html, body, *': {
      fontFamily: getAllFonts().join(', '),
    },
    'img': {
      objectFit: 'cover',
    },
    '.swiper-pagination': {
      'textAlign': 'right',
      'paddingRight': '102px',

      '.swiper-pagination-bullet': {
        width: '14px',
        height: '14px',
        margin: '0 9px !important',
        borderWidth: '1px',
        borderColor: 'primary.500',
        background: 'primary.200',
      },

      '.swiper-pagination-bullet-active': {
        background: 'primary.500',
      },
    },
    '.date-picker, .date-picker-seller, .date-picker-seller-edit': {
      w: '100%',
    },
    '.date-picker-input, .date-picker-seller, .date-picker-profile, .date-picker-seller-edit':
      {
        h: '40px',
        w: '100%',
        borderColor: 'primary.500',
        borderWidth: 1,
        borderRadius: '6px',
        pl: 4,
        pr: 10,
        fontSize: 'md',
        fontWeight: '500',
        _focus: {
          outline: 'none',
          boxShadow: 'none',
          borderColor: 'primary.500',
        },
      },

    '.date-picker-profile': {
      h: '51px',
      borderRadius: '8px',
      borderColor: 'primary.500',
    },

    '.rmdp-container': {
      width: '100%',
    },
    '.date-picker-seller, .date-picker-seller-edit': {
      borderColor: 'grey.300',
      _focus: {
        borderColor: 'grey.300',
      },
    },
    '.date-picker-seller-edit': {
      bg: 'transparent',
    },
    '.rmdp-range, .rmdp-panel-body li, .rmdp-panel-body li.bg-blue': {
      backgroundColor: 'primary.500 !important',
    },
    '.rmdp-day.rmdp-today span': {
      backgroundColor: 'white !important',
      color: 'primary.500 !important',
      fontWeight: 600,
    },
    '.rmdp-day.rmdp-selected span:not(.highlight)': {
      backgroundColor: 'primary.500 !important',
      color: 'white !important',
    },
    '.rmdp-arrow': {
      borderColor: 'primary.500 !important',
    },
    '.rmdp-week-day': {
      color: 'primary.500 !important',
    },
    '.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
      backgroundColor: 'primary.500 !important',
    },
    '.rmdp-arrow-container:hover': {
      backgroundColor: 'unset !important',
    },
    '.chakra-alert': {
      alignItems: 'center  !important',
      borderRadius: '0 !important',
      minHeight: '72px !important',
      padding: '12px 16px !important',
    },

    '.slider-main-div > div > img, .js-image-zoom__zoomed-image': {
      borderRadius: '10px !important',
      objectFit: 'contain',
      backgroundColor: 'grey.50',
    },
    '.js-image-zoom__zoomed-image': {
      zIndex: '1 !important',
    },

    '.custom-checkbox-style .chakra-checkbox__control': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'grey.900 !important',
    },

    '.custom-radio-style': {
      borderWidth: '1px !important',
      borderStyle: 'solid',
      borderColor: 'grey.900 !important',
    },

    '.custom-radio-style[data-checked]': {
      bg: 'white !important',
      color: 'grey.900 !important',
    },

    '.custom-checkbox-style .chakra-checkbox__control[data-checked]': {
      borderWidth: 1,
      borderColor: 'grey.900 !important',
      bg: 'grey.900 !important',
    },

    '.custom-checkbox-style .chakra-checkbox__control[data-checked]:before': {
      left: '0.22rem !important',
    },

    '.range-price > div': {
      bg: 'grey.900 !important',
    },

    '.range-price': {
      bg: 'rgba(43, 43, 43, .4) !important',
    },

    '.color-opacity': {
      opacity: 0.4,
    },
    '.filter_checkbox .chakra-checkbox__control': {
      borderWidth: 1,
      borderRadius: '8px',
      w: 6,
      h: 6,
    },

    '.filter_checkbox .chakra-checkbox__control[data-checked]': {
      bg: 'primary.500 !important',
    },
    '.filter_checkbox .chakra-checkbox__control[data-checked] + span': {
      color: 'grey.900 !important',
    },
    '.filter-date-picker': {
      boxShadow: 'none !important',
      bg: '#F4F5FA !important',
      w: 'full !important',
    },
    '.filter-date-picker .rmdp-range-hover': {
      bg: '#E7EAF9 !important',
    },
    '.filter-date-picker .rmdp-today span:hover': {
      bg: 'primary.500 !important',
      color: 'white !important',
    },
    '.filter-date-picker .rmdp-today span': {
      bg: 'white !important',
    },
    '.filter-date-picker .rmdp-top-class .rmdp-day.rmdp-today span': {
      fontWeight: 'normal',
    },
    '.filter-date-picker .rmdp-range span': {
      bg: 'transparent !important',
      color: 'white !important',
    },
    '.filter-date-picker .rmdp-day-picker, .filter-date-picker .rmdp-top-class ':
      {
        display: 'inherit !important',
      },
    '.filter-date-picker .rmdp-day, .rmdp-week-day ': {
      w: '48px !important',
      h: '48px !important',
    },
    '.star-ratings svg': {
      stroke: '#F7A606',
      strokeWidth: 2,
    },
    '.custom-date-time-picker': {
      width: '100%',
    },
    '.custom-date-time-picker .react-datetime-picker__wrapper ': {
      padding: '8px 6px',
      borderRadius: '8px',
      borderColor: '#D9D9D9',
    },
  },
};
